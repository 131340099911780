import { useLocation } from "react-router-dom";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import { usePageControls } from "../../../utils/components";
import { familyPaths } from "../../../routes/family/paths";
import useFamilies from "../../../requests/families";
import {
  CreateFamilyUserData,
  UpdateFamilyUserData
} from "../../../requests/families/types";



const useCreateEditUserUtils = () => {
  const { pathname } = useLocation();
  const { goTo } = usePageControls();
  const { setAppModal } = useAppModal();
  const { createUserInFamily, updateUserInFamily } = useFamilies();


  /***************************/
  /** Create new Family User */
  const createUser = (data: CreateFamilyUserData) => {
    createUserInFamily(data)
      .then((user) => {
        setAppModal({
          title: "Member Added!",
          children: (
            <div className="modal-content">
              <div className="bold">
                {user.firstName} has successfully been added to your Family.
              </div>
              <div className="grey-text mt-1">
                {user.familyRole === "admin" ? (
                  `They would need to check their email for${" "
                  }instructions on how to login.`
                ) : (
                  `They can login with the Username and Password ${" "
                  }you just set for them.`
                )}
              </div>
            </div>
          ),
          cancelCallback: () => {
            goTo(familyPaths.detail(pathname))
          }
        });
      })
      .catch(() => null);
  }

  /********************************/
  /** Update existing Family User */
  const updateUser = (userId: string, data: UpdateFamilyUserData) => {
    updateUserInFamily(userId, data)
      .then((user) => {
        setAppModal({
          title: "Changes Saved!",
          children: (
            <div className="modal-content">
              <div>
                Changes for {user.firstName} been applied successfully!
              </div>
            </div>
          ),
          cancelCallback: () => {
            goTo(familyPaths.detail(pathname))
          }
        });
      })
      .catch(() => null);
  }


  return {
    createUser,
    updateUser
  }
}

export default useCreateEditUserUtils;