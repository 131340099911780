import { securityPaths } from "./paths";
import { RouteType } from "../types";
import {
  VerifyEmail,
  EmailVerified
} from "../../pages/Security/pages";



/********************/
/** Security Routes */
export const securityRoutes: RouteType[] = [
  {
    path: securityPaths.VERIFY_EMAIL,
    element: <VerifyEmail />
  },
  {
    path: securityPaths.EMAIL_VERIFIED,
    element: <EmailVerified />
  }
]