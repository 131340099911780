import { hasActiveSubscription } from "../subscriptions/utils";
import useSubscriptions from "../subscriptions";
import { PlanType } from "../plans/types";
import { User } from "../users/types";
import { Onboarding } from "./types";



const useOnboardingUtils = () => {
  const { getMySubscription } = useSubscriptions();


  /**********************************************************/
  /** Calculate what is required in the secondary checklist */
  const buildSecondaryChecklist = (onboarding: Onboarding, user: User) => {
    return new Promise<Partial<Onboarding>>((resolve) => {
      const partialOnboarding: Partial<Onboarding> = {
        id: onboarding.id,
        avatar: onboarding.avatar,
        heritages: onboarding.heritages,
        createdAt: onboarding.createdAt,
        completedKyc: onboarding.completedKyc,
        updatedAt: onboarding.updatedAt
      }
      getMySubscription()
        .then((subscription) => {
          const activeSubscription = hasActiveSubscription(subscription);
          if (activeSubscription) {
            const familyPlans: PlanType[] = ["standard", "family", "familyPlus"];
            // User must be on a plan that supports multiple users
            if (familyPlans.includes(
              activeSubscription.planPrice.plan.planType
            )) {
              // ...and user must be a parent
              if (user.familyRole === "admin") {
                partialOnboarding.family = onboarding.family;
              }
            }
          }
        })
        .catch(() => { })
        .finally(() => {
          resolve(partialOnboarding);
        });
    });
  }


  return {
    buildSecondaryChecklist
  }
}

export default useOnboardingUtils;