import { useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { getCountryCodes } from "../../../constants";



export type AddressFieldsType =
  | "auto"
  | "manual";

const useAddressEntryUtils = () => {
  const [addressValues, setAddressValues] = useState<FormValues>({});
  const [fieldsType, setFieldsType] = useState<AddressFieldsType>("auto");


  /**************************/
  /** Manual address fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "addressLine1",
      label: "Address Summary",
      maxLength: 100,
      groupClass: "field-group 1"
    },
    {
      type: "text",
      name: "houseNumber",
      label: "House Number",
      required: false,
      maxLength: 26,
      groupClass: "field-row 2"
    },
    {
      type: "text",
      name: "houseName",
      label: "House Name",
      required: false,
      maxLength: 26,
      groupClass: "field-row 2"
    },
    {
      type: "text",
      name: "flat",
      label: "Flat",
      required: false,
      maxLength: 20,
      groupClass: "field-row 3"
    },
    {
      type: "text",
      name: "street",
      label: "Street",
      required: false,
      maxLength: 32,
      groupClass: "field-row 3"
    },
    {
      type: "text",
      name: "city",
      label: "City",
      maxLength: 28,
      groupClass: "field-row 4"
    },
    {
      type: "text",
      name: "postCode",
      label: "Post Code",
      maxLength: 8,
      groupClass: "field-row 4"
    },
    {
      type: "text",
      name: "county",
      label: "County",
      required: false,
      groupClass: "field-row 5"
    },
    {
      type: "text",
      name: "country",
      label: "Country",
      disabled: true,
      groupClass: "field-row 5"
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group 3",
      reactNode: (
        <div className="flex column address-mode">
          <div className="field-info">
            Not sure of your address?
          </div>
          <button
            type="button"
            className="link"
            onClick={(e) => {
              e.preventDefault();
              setFieldsType("auto");
            }}
          >
            Search for your address
          </button>
        </div>
      )
    },
    {
      type: "select",
      name: "countryCode",
      label: "Country Code",
      placeholder: "Select",
      options: getCountryCodes(),
      className: "country-code",
      groupClass: "field-row phone"
    },
    {
      type: "text",
      name: "phoneNumber",
      label: "Phone Number",
      placeholder: "Phone Number",
      className: "phone-number",
      maxLength: 11,
      groupClass: "field-row phone"
    }
  ]



  return {
    formFields,
    addressValues,
    setAddressValues,
    fieldsType,
    setFieldsType
  }
}

export default useAddressEntryUtils;