import { SelectFieldOption } from "../components/forms/SelectField";
import { FamilyRole } from "../requests/users/types";



type FamilyRoleSelect = SelectFieldOption & {
  value: FamilyRole;
}
export const familyRoles: FamilyRoleSelect[] = [
  {
    value: "admin",
    label: "Parent"
  },
  {
    value: "member",
    label: "Learner"
  }
]

const relationships = [
  "mother",
  "father",
  "brother",
  "sister",
  "daughter",
  "son",
  "husband",
  "wife",
  "spouse",
  "grandfather",
  "grandmother",
  "friend",
  "uncle",
  "auntie",
  "cousin",
  "niece",
  "nephew"
]

export const familyRelationships = relationships.map(value => ({
  value,
  label: value.charAt(0).toUpperCase() + value.slice(1)
}));


export const getVerboseFamilyRole = (type: FamilyRole) => {
  switch (type) {
    case "admin":
      return "Parent";
    case "member":
      return "Learner";
    default:
      return "Parent";
  }
}

export const UserFilters: SelectFieldOption[] = [
  {
    value: "",
    label: "All Users"
  },
  ...familyRoles,
]