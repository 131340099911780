import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getVerboseRegionName } from "../../../constants/core";
import { missionPaths } from "../../../routes/missions/paths";
import { UserTree } from "../../../requests/trees/types";
import {
  ReactComponent as Map
} from "../../../assets/vector-images/missions/map.svg";
import useTrees from "../../../requests/trees";
import Styles from "./styles";



function MapOfSpaceAndTime() {
  const { getUserTrees } = useTrees();
  const [userTrees, setUserTrees] = useState<UserTree[]>([]);
  useEffect(() => {
    getUserTrees()
      .then((results) => {
        const sortedResults = results.sort(
          (a, b) => a.tree.desert.name.localeCompare(b.tree.desert.name)
        );
        setUserTrees(sortedResults); setUserTrees(results);
      }).catch(() => null);
  }, [])


  return (
    <Styles className="map-of-space-and-time flex column">
      <ul className="flex wrap center user-trees">
        {userTrees.map((userTree) =>
          <Link
            key={userTree.id}
            to={`${missionPaths.desertMissions(userTree.tree.desertId)
              }/?treeId=${userTree.tree.id}`}
            className="user-tree flex align-center"
          >
            <div className="role generic">
              {userTree.tree.desert.name}
            </div>
            <span>-</span>
            <div className={`role ${userTree.tree.region}`}>
              {getVerboseRegionName(userTree.tree.region)}
            </div>
          </Link>
        )}
      </ul>
      <div className="map-container">
        <Map id="map" />
      </div>
    </Styles>
  );
}

export default MapOfSpaceAndTime;
