import { subscriptionPaths } from "./subscriptions/paths";
import { profileSetupPaths } from "./profileSetup/paths";
import { downloadPaths } from "./download/paths";
import { securityPaths } from "./security/paths";
import { consolePaths } from "./_console/paths";
import { missionPaths } from "./missions/paths";
import { familyPaths } from "./family/paths";
import { rootPaths } from "./paths";
import { RouteType } from "./types";
import {
  ProfileSetup,
  Security,
  Subscriptions,
  Download,
  Console,
  Family,
  Missions
} from "../pages";



/***************/
/* ROOT ROUTES */
export const rootRoutes: RouteType[] = [
  {
    path: rootPaths.SECURITY,
    element: <Security />,
    redirect: securityPaths.VERIFY_EMAIL
  },
  {
    path: rootPaths.SUBSCRIPTIONS,
    element: <Subscriptions />,
    redirect: subscriptionPaths.LIST
  },
  {
    path: rootPaths.PROFILE_SETUP,
    element: <ProfileSetup />,
    redirect: profileSetupPaths.COUNTRY_ENTRY
  },
  {
    path: rootPaths.DOWNLOAD,
    element: <Download />,
    redirect: downloadPaths.DOWNLOAD_APP
  },
  {
    path: rootPaths.FAMILY,
    element: <Family />,
    redirect: familyPaths.detail()
  },
  {
    path: rootPaths.MISSIONS,
    element: <Missions />,
    redirect: missionPaths.map()
  },
  {
    path: "/start-mission",
    element: <Missions />,
    redirect: missionPaths.ONBOARDING_LESSON
  },
  {
    path: rootPaths.CONSOLE,
    element: <Console />,
    redirect: consolePaths.DASHBOARD
  }
]