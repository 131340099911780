import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  .description {
    color: ${colors.grey6};
  }
  .alt-entry {
    margin-top: 3rem;
  }
  .forgot-password {
    color: ${colors.grey6};
    font-size: 0.8rem;
  }
  .privacy-terms {
    margin-top: 3rem;
    font-size: 13px;
    font-weight: 500;
    max-width: ${properties.maxFieldWidth};
    text-align: center;
    color: ${colors.grey6};
  }
`;

export default Styles;