import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  max-width: ${properties.maxFormWidth} !important;
  .form {
    .address-mode {
      margin-bottom: 1rem;
      button.link {
        font-size: 0.8rem;
      }
    }
  }
  .search-results, .searching {
    border-radius: 7px;
    background-color: ${colors.white};
    box-shadow: ${properties.boxShadow};
    margin-bottom: 1rem;
    padding: 1rem;
  }
  .searching {
    .spinner {
      padding: 0;
      margin: 0;
      .circle {
        border-width: 2px;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .search-results {
    li {
      &:not(:last-child) {
        border-bottom: 1px solid ${colors.grey1};
      }
      .result {
        padding: 0.5rem 0;
        font-size: 0.8rem;
        color: ${colors.grey6};
        width: 100%;
        text-align: left;
        &:hover {
          color: ${colors.primary};
        }
      }
    }
  }
`;

export default Styles;