import { Link } from "react-router-dom";

import id_error from
  "../../../../assets/vector-images/panel/id-card-error.svg";
import { InfoPanel } from "../../../../components/layout";
import { rootPaths } from "../../../../routes/paths";



function KYCCompleteFail() {
  return (
    <div className="page-wrapper">
      <InfoPanel
        icon={id_error}
        title="Verification Failed"
        description={(
          <>
            <div>
              Unfortunately, after trying multiple times, we couldn't validate your identity.
            </div>
            <div>
              Contact{" "
              }<span className="bold">
                support@schoolofnewafrica.com
              </span>{" "}for assistance.
            </div>
          </>
        )}
      >
        <Link
          to={rootPaths.HOMEPAGE}
          className="button white-black"
        >
          Okay
        </Link>
      </InfoPanel>
    </div>
  );
}

export default KYCCompleteFail;