import { Link } from "react-router-dom";

import { missionPaths } from "../../../routes/missions/paths";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import Styles from "./styles";



function SignUp() {
  return (
    <Styles className="root-content bg-action-pose">
      <div className="page-wrapper field-width centered">
        {/* Heading */}
        <header>
          <h3 className="title">
            Get Access to SONA
          </h3>
          <div className="description">
            Be one of the first to join the SONA community when it{" "}
            launches to the public
          </div>
        </header>

        {/* Sign-Up Form */}
        <section className="flex column center">
          <p className="message al-center mb-2 bold">
            The SONA app is currently only avaliable for beta testing.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mailchi.mp/schoolofnewafrica.com/sona-app-waitinglist"
            className="button"
          >
            Join the Waiting List
          </a>
          <Link
            to={missionPaths.ONBOARDING_LESSON}
            className="button white-black"
          >
            Try a Live Demo
          </Link>
        </section>

        {/* Alternate Authentication (Sign In) */}
        <section className="alt-entry flex center">
          <div>Already have an account?</div>
          <Link
            to={nonAuthPaths.SIGN_IN}
            className="link underline black"
          >
            Sign In
          </Link>
        </section>
      </div>
    </Styles>
  );
}

export default SignUp;