import styled from "styled-components";

import { colors } from "../../../../styles";



const Styles = styled.div`
  section {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    .list {
      list-style: disc;
      padding-left: 1.5rem;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
    svg {
      path {
        stroke: ${colors.grey2};
      }
    }
  }
`;

export default Styles;