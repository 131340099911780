import styled from "styled-components";

import check_2 from "../../../assets/vector-images/generic/check-2.svg";
import { colors, properties } from "../../../styles";



const avatarHeight = "7rem";

const Styles = styled.div`
  max-width: ${properties.maxFormWidth} !important;
  ul.avatar-list {
    margin-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    row-gap: 1.5rem;
    li.avatar-wrapper {
      width: ${avatarHeight};
      height: ${avatarHeight};
      &.active {
        button.avatar {
          position: fixed;
          top: 15rem;
          left: 50%;
          transform: translateX(-50%) scale(1.5) ;
          z-index: 1;
          background-color: ${colors.white} !important;
          box-shadow: 1px 25px 50px 10px rgba(0,0,0,0.59);
          width: ${avatarHeight};
          height: ${avatarHeight};
          &::after {
            content: "";
            background-color: ${colors.white};
            height: 1.5rem;
            width: 1.5rem;
            background-image: url(${check_2});
            background-size: 80%;
            position: absolute;
            border-radius: 50%;
            bottom: 0;
            right: 1rem;
            background-position: center;
          }
        }
      }
      button.avatar {
        width: 100%;
        height: 100%;
        padding: 1rem;
        border-radius: 50%;
        transition: transform ${properties.transition};
        border: 3px solid transparent;
        &:focus {
          border-color: ${colors.primary};
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  /* Save button */
  button.save-avatar {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: 340px;
    &:active {
      transform: translateY(5px) translateX(-50%);
    }
  }
`;

export default Styles;