import { MouseEvent, useEffect, useRef } from "react";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import { FormField } from "../../../components/forms/Form/types";
import ResetUserPassword from "./ResetUserPassword";
import { Form } from "../../../components/forms";
import { FinalFormPage } from "./types";



function Credentials({
  FormNavigation,
  values: formValues,
  setValues: setFormValues,
  emailUsername,
  saveDetails,
  user,
}: FinalFormPage) {
  const formRef = useRef<HTMLFormElement>(null);
  const { setAppModal } = useAppModal();


  const openResetPassword = (e: MouseEvent) => {
    e.preventDefault();
    if (!user) return;
    setAppModal({
      title: "Reset Password",
      children: (
        <ResetUserPassword
          user={user}
          fields={resetPasswordFields}
        />
      ),
      controls: (
        <div />
      )
    });
  }

  /**************************/
  /** Reset Password Fields */
  const resetPasswordFields: FormField[] = [
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Enter a secure password",
      autoComplete: "new-password",
      username: formValues.username,
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "confirm-password",
      label: "Confirm Password",
      placeholder: "Re-enter the password",
      autoComplete: "new-password",
      groupClass: "field-group"
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group",
      reactNode: (
        <div className="grey-text">
          Remember to keep this password in a safe place.
          <br />
          If you forget it, you would have to reset it for this Learner
        </div>
      )
    }
  ]

  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group 3",
      reactNode: (
        <div className="heading">
          <div className="title">
            Account Access
          </div>
          <div className="description grey-text">
            {(!user) ? (
              (emailUsername) ? (
                // New Parent
                <>
                  Add the Parent's email address and they'll{" "}
                  be sent instructions on how to sign in
                </>
              ) : (
                // New Child
                <>
                  Create a unique username and a memorable{" "}
                  password for your child's account
                </>
              )
            ) : (
              (emailUsername) ? (
                <>
                  You cannot change the email or password{" "}
                  for another Parent
                </>
              ) : (
                <>
                  Edit your Learner's Credentials
                </>
              )
            )}
          </div>
        </div>
      )
    },
    {
      type: emailUsername
        ? "email"
        : "text",
      name: "username",
      label: emailUsername
        ? "Email"
        : "Username",

      placeholder: emailUsername
        ? "parent@example.com"
        : "E.g. kwaku-ananse",
      disabled: user !== undefined,
      groupClass: "field-group"
    }
  ]

  if (!emailUsername) {
    if (!user) {
      // Create New Password
      fields.push(...resetPasswordFields);
    } else {
      // Reset Password
      fields.push({
        type: "react-node",
        name: "",
        label: "",
        groupClass: "field-group",
        reactNode: (
          <div className="flex column">
            <div className="field-info mt-1">
              If you've forgotten your Learner's password,{" "}
              you can reset it here.
            </div>
            <button
              className="button auto-width"
              onClick={openResetPassword}
            >
              Reset Password
            </button>
          </div>
        )
      });
    }
  }


  useEffect(() => {
    if (!user) return;
    const { username } = user;
    setFormValues((prevState) => ({
      ...prevState,
      username
    }));
  }, [user])



  return (
    <Form
      values={formValues}
      setValues={setFormValues}
      onSubmit={saveDetails}
      ref={formRef}
      fields={fields}
    >
      <FormNavigation
        next={(
          <button
            className="button auto-width next"
            type="submit"
          >
            {user
              ? "Save Changes"
              : emailUsername
                ? "Add Parent"
                : "Add Learner"
            }
          </button>
        )}
      />
    </Form>
  );
}

export default Credentials;