import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { missionRoutes, onboardingMissionRoutes } from "../routes/missions";
import { consoleFamilyRoutes, consoleRoutes } from "../routes/_console";
import { ModalProvider } from "../components/layout/AppModal/utils";
import { subscriptionRoutes } from "../routes/subscriptions";
import { profileSetupRoutes } from "../routes/profileSetup";
import ConsoleFamily from "../pages/_console/Family";
import { downloadRoutes } from "../routes/download";
import { securityRoutes } from "../routes/security";
import { ScrollToTop } from "../components/layout";
import { store, persistor } from "../redux/store";
import GlobalStyles from "../styles/GlobalStyles";
import { nonAuthRoutes } from "../routes/nonAuth";
import {
  AuthRoute, NonAuthRoute, OnboardingMissionRoute
} from "../components/auth";
import {
  Home, Page404, Console, Security, Subscriptions,
  ProfileSetup, Download, Family, Missions
} from "../pages";
import { familyRoutes } from "../routes/family";
import { rootPaths } from "../routes/paths";
import { rootRoutes } from "../routes";
import { mapRoutes } from "./utils";
import "../styles/fonts.css";



function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ModalProvider>
          <Router>
            <ScrollToTop />
            <GlobalStyles />
            <Routes>
              <Route path={rootPaths.HOMEPAGE} element={<Home />} />
              {/*######################*/}
              {/* AUTHENTICATED ROUTES */}
              <Route element={<AuthRoute />}>
                {/* Root Routes */}
                {mapRoutes(rootRoutes)}

                {/************/}
                {/* Security */}
                <Route element={<Security />}>
                  {mapRoutes(securityRoutes)}
                </Route>

                {/*****************/}
                {/* Subscriptions */}
                <Route element={<Subscriptions />}>
                  {mapRoutes(subscriptionRoutes)}
                </Route>

                {/*****************/}
                {/* Profile Setup */}
                <Route element={<ProfileSetup />}>
                  {mapRoutes(profileSetupRoutes)}
                </Route>

                {/************/}
                {/* Download */}
                <Route element={<Download />}>
                  {mapRoutes(downloadRoutes)}
                </Route>

                {/**********/}
                {/* Family */}
                <Route element={<Family />}>
                  {mapRoutes(familyRoutes)}
                </Route>

                {/**********/}
                {/* Missions */}
                <Route element={<Missions />}>
                  {mapRoutes(missionRoutes)}
                </Route>

                {/***********/}
                {/* Console */}
                <Route element={<Console />}>
                  {mapRoutes(consoleRoutes)}
                  {/* Console > Family */}
                  <Route element={<ConsoleFamily />}>
                    {mapRoutes(consoleFamilyRoutes)}
                  </Route>
                </Route>
              </Route>


              {/*##########################*/}
              {/* NON-AUTHENTICATED ROUTES */}
              <Route element={<NonAuthRoute />}>
                {mapRoutes(nonAuthRoutes)}
              </Route>

              {/*##########################*/}
              {/* ONBOARDING MISSION ROUTES */}
              <Route element={<OnboardingMissionRoute />}>
                {mapRoutes(onboardingMissionRoutes)}
              </Route>

              {/* Page Not Found */}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Router>
        </ModalProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;