import { Navigate } from "react-router-dom";

import { consolePaths } from "../../routes/_console/paths";
import { nonAuthPaths } from "../../routes/nonAuth/paths";
import { useAppState } from "../../redux/state";



function Home() {
  const { token } = useAppState();
  return (
    <Navigate
      replace
      to={token
        ? consolePaths.DASHBOARD
        : nonAuthPaths.SIGN_IN
      }
    />
  );
}

export default Home;