import styled from "styled-components";

import { functions, colors } from "../../../styles";



const Styles = styled.figcaption`
  padding: 1rem;
  border-radius: 10px;
  background-color: ${functions.rgba(colors.black, 0.05)};
  gap: 10px;
  .image {
    height: 40px;
    max-width: 40px;
  }
  .no-name {
    color: ${colors.grey12}
  }
  .details {
    gap: 2px;
    .name {
      font-weight: 500;
    }
    .family {
      line-height: unset;
    }
  }
`;

export default Styles;