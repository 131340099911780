import { useEffect, useState } from "react";

import FormSteps, { useFormNavigation } from "../../../components/forms/FormSteps";
import { FormStep } from "../../../components/forms/FormSteps/types";
import { FormValues } from "../../../components/forms/Form/types";
import { Family } from "../../../requests/families/types";
import useFamilies from "../../../requests/families";
import CrestForm from "./CrestForm";
import NameForm from "./NameForm";
import Styles from "./styles";



function FamilySetup() {
  const { getMyFamily } = useFamilies();
  const [family, setFamily] = useState<Family>();
  const {
    currentStep, FormNavigation, nextStep, previousStep
  } = useFormNavigation();
  const [nameValues, setNameValues] = useState<FormValues>({});
  const [crestValues, setCrestValues] = useState<FormValues>({});


  const formSteps: FormStep[] = [
    {
      title: "Family Name",
      content: (
        <NameForm
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={nameValues}
          setValues={setNameValues}
          family={family}
        />
      )
    },
    {
      title: "Choose Crest",
      content: (
        <CrestForm
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={crestValues}
          setValues={setCrestValues}
          family={family}
        />
      )
    }
  ];


  useEffect(() => {
    getMyFamily()
      .then((family) => {
        setFamily(family);
      }).catch(() => null);
  }, [])



  return (
    <Styles className="page-wrapper family-setup">
      <header>
        <div className="flex">
          <h1 className="title">
            Family Setup
          </h1>
        </div>
      </header>
      <FormSteps
        currentStep={currentStep}
        steps={formSteps}
      />
    </Styles>
  );
}

export default FamilySetup;