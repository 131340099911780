import { familyPaths } from "./paths";
import { RouteType } from "../types";
import {
  CreateEditUser,
  FamilyDetail,
  CreateInvite,
  ViewInvites,
  FamilySetup
} from "../../pages/Family/pages";



/******************/
/** Family Routes */
export const familyRoutes: RouteType[] = [
  {
    path: familyPaths.setup(),
    element: <FamilySetup />
  },
  {
    path: familyPaths.detail(),
    element: <FamilyDetail />
  },
  {
    path: familyPaths.addUser(),
    element: <CreateEditUser />
  },
  {
    path: familyPaths.EDIT_USER,
    element: <CreateEditUser />
  },
  {
    path: familyPaths.createInvite(),
    element: <CreateInvite />
  },
  {
    path: familyPaths.viewInvites(),
    element: <ViewInvites />
  }
]