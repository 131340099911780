import axios from "axios";

import { ErrorResponse } from "../../types";
import { Plan } from "./types";



/*  ###################### */
/** ## PLAN API REQUESTS # */
/*  ###################### */
const usePlans = () => {
  /****************************/
  /** List of available plans */
  const listPlans = (params?: Record<string, string>) => {
    return new Promise<Plan[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plans/`,
        params
      }).then((res) => {
        const resData = res.data.results as Plan[];
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listPlans
  }
}

export default usePlans;