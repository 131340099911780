import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  .country-list {
    height: 15rem;
  }
  .grid-row {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));;
  }
  .data-block {
    .title {
      margin-bottom: 1rem;
      color: ${colors.grey2};
    }
    .psuedo-table {
      border: 1px solid ${colors.grey1};
      border-radius: 7px;
      overflow: hidden;
      .psuedo-table-data {
        padding: 1rem;
        background-color: ${colors.grey15};
        &:not(:last-child) {
          border-bottom: 1px solid ${colors.grey1};
        }
        .key {
          color: ${colors.grey13};
        }
        .value {
          font-weight: 600;
        }
      }
    }
  }
`;

export default Styles;