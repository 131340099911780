import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import FormSteps, { useFormNavigation } from "../../../components/forms/FormSteps";
import { FormStep } from "../../../components/forms/FormSteps/types";
import { FormValues } from "../../../components/forms/Form/types";
import useFamilies from "../../../requests/families";
import { User } from "../../../requests/users/types";
import { Loader } from "../../../components/layout";
import { URLParams } from "../../../constants";
import useCreateEditUserUtils from "./utils";
import {
  CreateFamilyUserData,
  UpdateFamilyUserData
} from "../../../requests/families/types";
import Credentials from "./Credentials";
import Permissions from "./Permissions";
import { FamilyContext } from "..";
import Nickname from "./Nickname";
import Name from "./Name";
import Dob from "./Dob";



function CreateEditUser() {
  const { userId } = useParams<URLParams>();
  const { getUserInFamily } = useFamilies();
  const { createUser, updateUser } = useCreateEditUserUtils();
  const { user: loggedInUser } = useOutletContext<FamilyContext>();
  const {
    currentStep, FormNavigation, nextStep, previousStep
  } = useFormNavigation();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [emailUsername, setEmailUsername] = useState<boolean>(false);
  const [nameValues, setNameValues] = useState<FormValues>({});
  const [nickNameValues, setNicknameValues] = useState<FormValues>({});
  const [dobValues, setDobValues] = useState<FormValues>({});
  const [permissionValues, setPermissionValues] = useState<FormValues>({});
  const [credentialValues, setCrendentialValues] = useState<FormValues>({});


  /****************/
  /** Submit Data */
  const saveDetails = () => {
    const { familyRole, familyRelationship } = permissionValues;
    const { username, password } = credentialValues;
    const { firstName, lastName, middleName } = nameValues;
    const { nickName } = nickNameValues;
    const { dob } = dobValues;

    if (!userId) {
      // Create a new User
      const data: CreateFamilyUserData = {
        userType: "basic",
        familyRole,
        familyRelationship,
        firstName,
        lastName,
        middleName,
        nickName,
        dob,
        username,
        ...(password ? {
          password
        } : {})
      }
      createUser(data);
    }
    else {
      // Update Existing User
      const data: UpdateFamilyUserData = {
        userId,
        familyRole,
        familyRelationship,
        firstName,
        lastName,
        middleName,
        nickName,
        dob
      }
      updateUser(userId, data);
    }
  }


  /****************/
  /** Form Fields */
  const formSteps: FormStep[] = [
    {
      title: "Permissions",
      content: (
        <Permissions
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={permissionValues}
          setValues={setPermissionValues}
          emailUsername={emailUsername}
          setEmailUsername={setEmailUsername}
          loggedInUser={loggedInUser}
          user={user}
        />
      )
    },
    {
      title: "Name",
      content: (
        <Name
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={nameValues}
          setValues={setNameValues}
          emailUsername={emailUsername}
          setEmailUsername={setEmailUsername}
          loggedInUser={loggedInUser}
          user={user}
        />
      )
    },
    {
      title: "Nickname",
      content: (
        <Nickname
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={nickNameValues}
          setValues={setNicknameValues}
          emailUsername={emailUsername}
          setEmailUsername={setEmailUsername}
          loggedInUser={loggedInUser}
          user={user}
        />
      )
    },
    {
      title: "DOB",
      content: (
        <Dob
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={dobValues}
          setValues={setDobValues}
          emailUsername={emailUsername}
          setEmailUsername={setEmailUsername}
          loggedInUser={loggedInUser}
          user={user}
        />
      )
    },
    {
      title: "Credentials",
      content: (
        <Credentials
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          values={credentialValues}
          setValues={setCrendentialValues}
          emailUsername={emailUsername}
          setEmailUsername={setEmailUsername}
          saveDetails={saveDetails}
          loggedInUser={loggedInUser}
          user={user}
        />
      )
    }
  ]


  useEffect(() => {
    if (!userId) return;
    setLoading(true);
    getUserInFamily(userId)
      .then((user) => {
        setUser(user);
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }, [])



  return (
    loading ? (
      <Loader />
    ) : (
      <div className="page-wrapper">
        <header>
          <h1 className="title">
            {!user ? "Create" : "Edit"} Family Member
          </h1>
          <div className="description">
            {!user ? (
              "Create and add a new user to your family"
            ) : (
              `Edit ${user.firstName || "User"}'s details`
            )}
          </div>
        </header>
        <FormSteps
          currentStep={currentStep}
          steps={formSteps}
        />
      </div>
    )
  );
}

export default CreateEditUser;