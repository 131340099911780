import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import useFamilyInvites from "../../../requests/familyInvites";
import PreviouslyAccepted from "./PreviouslyAccepted";
import UnauthorizedInvite from "./UnauthorizedInvite";
import { Loader } from "../../../components/layout";
import { URLParams } from "../../../constants";
import AcceptedInvite from "./AcceptedInvite";
import ExpiredInvite from "./ExpiredInvite";
import NoSeats from "./NoSeats";



function CheckInvite() {
  const { familyInviteId } = useParams<URLParams>();
  const { acceptFamilyInvite } = useFamilyInvites();
  const [outputElement, setOutputElement] = useState<
    JSX.Element
  >(<Loader message="Checking Invitation Status" />);


  useEffect(() => {
    if (!familyInviteId) return;
    acceptFamilyInvite(familyInviteId)
      .then((resData) => {
        const { status, familyInvite } = resData;
        switch (status) {
          case "accepted":
            setOutputElement(
              <AcceptedInvite
                familyName={familyInvite.family.name}
              />
            );
            break;
          case "no-seats":
            setOutputElement(
              <NoSeats />
            );
            break;
          case "unauthorized":
            setOutputElement(
              <UnauthorizedInvite />
            );
            break;
          case "previously-accepted":
            setOutputElement(
              <PreviouslyAccepted />
            );
            break;
          case "expired":
            setOutputElement(
              <ExpiredInvite />
            );
            break;
          default:
            setOutputElement(
              <Loader
                message="Checking Invitation Status"
              />
            );
        }
      }).catch(() => null);
  }, [familyInviteId])



  return (
    <div className="page-wrapper centered">
      {outputElement}
    </div>
  );
}

export default CheckInvite;