import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";



// Context (Props) passed down to Child Pages (<Outlet/>)
export type SubscriptionsContext = AuthRouteContext;

function Subscriptions() {
  const { user } = useOutletContext<SubscriptionsContext>();



  return (
    <div className="root-content">
      <Outlet context={{ user }} />
    </div>
  );
}

export default Subscriptions;