import { useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import Form from "../../../components/forms/Form";
import useAuth from "../../../requests/auth";
import Styles from "./styles";



function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const { resetPassword, requestPasswordReset } = useAuth();
  const [formValues, setFormValues] = useState<FormValues>({});
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get("username");
  const { setAppModal } = useAppModal();


  /****************/
  /** Submit Data */
  const submitData = () => {
    if (!username) {
      navigate(nonAuthPaths.FORGOT_PASSWORD);
      return;
    }
    resetPassword({
      username,
      code: formValues.code,
      password: formValues.password
    }).then((res) => {
      let message = res.data.details[0];
      if (!(message && (typeof message === "string"))) {
        message = (
          `Your password has been successfully reset!${" "
          }You can now login with it.`
        );
      }
      setAppModal({
        title: "Password Reset",
        children: message,
        cancelCallback: () => {
          navigate(nonAuthPaths.SIGN_IN)
        }
      });
    }).catch(() => null)
  }


  /*****************************/
  /** Resend Verification Code */
  const resendCode = () => {
    if (!username) {
      navigate(nonAuthPaths.FORGOT_PASSWORD);
      return;
    }
    requestPasswordReset({ username })
      .then(() => null)
      .catch(() => null);
  }


  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "code",
      label: "Verification Code",
      placeholder: "Enter the code you received",
      autoComplete: "off",
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Your new secure password",
      autoComplete: "new-password",
      username: username,
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "confirm-password",
      label: "Confirm Password",
      placeholder: "Re-enter your password",
      autoComplete: "new-password",
      groupClass: "field-group"
    }
  ]



  return (
    <Styles className="root-content bg-action-pose">
      <div className="page-wrapper field-width centered">
        {/* Heading */}
        <header>
          <h3 className="title">
            Reset Password
          </h3>
          <div className="description">
            Enter the Verification Code you received{" "
            }along with your new Password to get started
          </div>
        </header>
        {/* Reset Password Form */}
        <section>
          <Form
            fields={formFields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={submitData}
            ref={formRef}
          >
            <button
              className="button submit"
              type="submit"
            >
              Reset Password
            </button>
          </Form>
          <div className="flex forgot-password mt-1">
            <div>Didn't receive the code?</div>
            <button
              className="link"
              onClick={resendCode}
            >
              Resend Code
            </button>
          </div>
        </section>
      </div>
    </Styles>
  );
}

export default ResetPassword;