import { useNavigate, useOutletContext } from "react-router-dom";
import { FormEvent, MouseEvent, useEffect } from "react";
import { getName } from "country-list";

import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../requests/users/types";
import { CountrySelect } from "../../../components/forms";
import { useInput } from "../../../utils/forms";
import useUsers from "../../../requests/users";
import { ProfileSetupContext } from "..";
import Styles from "./styles";



function CountryEntry() {
  const navigate = useNavigate();
  const { updateProfile } = useUsers();
  const { address } = useOutletContext<ProfileSetupContext>();
  const {
    value: country,
    setValue: setCountry,
    stringChange: countryCh
  } = useInput({
    initialValue: ""
  });
  const {
    value: search,
    setValue: setSearch,
    stringChange: searchCh
  } = useInput({
    initialValue: ""
  });


  /*****************/
  /** Save Country */
  const saveData = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    const profileData: UpdateProfileArgs = {
      address: {
        country
      }
    }
    updateProfile(profileData)
      .then(() => {
        navigate(profileSetupPaths.SAVE_CARD);
      }).catch(() => null);
  }

  useEffect(() => {
    if (!address) return;
    const { country } = address;
    if (country && country.length === 2) {
      setCountry(country);
      setSearch(getName(country) ?? "")
    }
  }, [address])



  return (
    <Styles className="page-wrapper flex column center">
      {/* Heading */}
      <header>
        <h3 className="title">
          Get Started on SONA
        </h3>
        <div className="description">
          Select your country of residence to continue
        </div>
      </header>

      {/* Country Selection */}
      <section className="country-selection">
        <CountrySelect
          country={country}
          countryCh={countryCh}
          search={search}
          searchCh={searchCh}
        />
        <button
          className="button mlr-auto submit"
          disabled={!country}
          onClick={saveData}
        >
          Continue
        </button>
      </section>
    </Styles>
  );
}

export default CountryEntry;
