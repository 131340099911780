import styled from "styled-components";

import {
  colors, functions, properties, resolutions, snippets
} from "../../../styles";



const Styles = styled.div`
  flex: unset !important;
  flex-basis: ${properties.sidebarWidth} !important;
  flex-shrink: 0 !important;
  z-index: ${properties.highestZIndex};
  @media(max-width: ${resolutions.tabletLarge}) {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    transform: translateX(-100%);
    background-color: ${functions.rgba(colors.black, 0.5)};
    transition: background-color 150ms ease-out;
    &.open {
      transform: translateX(0%);
      .sidebar-wrapper {
        transform: translateX(0%);
        background-color: ${colors.white};
      }     
    }
  }

  .sidebar-wrapper {
    padding: 36px 24px;
    background-color: transparent;
    transition: transform 150ms ease-out;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: 100%;
    @media(max-width: ${resolutions.tabletLarge}) {
      transform: translateX(-100%);
    }
    .nav-links {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .nav-link {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 15rem;
        border-radius: 24px;
        gap: 15px;
        &.active {
          background-color: ${functions.rgba(colors.primary, 0.125)};
          .icon {
            svg {
              path {
                stroke: ${colors.primary};
              }
            }
          }
          .text {
            color: ${colors.primary};
            font-weight: 600;
          }
        }
        .icon {
          ${snippets.flexCenter}
          svg {
            width: 30px;
            height: 30px;
            path {
              stroke: ${colors.grey3};
            }
          }
        }
        .text {
          font-size: 15px;
          color: ${colors.grey3};
          font-weight: 600;
        }
      }
    }
  }
`;

export default Styles;