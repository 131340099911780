import { FormEvent, useEffect, useRef, useState } from "react";

import DobField from "../../../components/forms/DobField";
import { FormPageProps } from "./types";



function Dob({
  FormNavigation,
  values: formVaules,
  setValues: setFormValues,
  nextStep,
  user,
  emailUsername
}: FormPageProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const usersPronoun = emailUsername ? "parent's" : "learner's";
  const [dobValue, setDobValue] = useState<Date | string>("");
  const [validDob, setValidDob] = useState<boolean>(false);


  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (dobValue && validDob) {
      nextStep();
    } else {
      return;
    }
  }

  useEffect(() => {
    if (!user) return;
    const { dob } = user;
    if (!dob) return;
    setDobValue(dob);
  }, [user])

  useEffect(() => {
    if (!dobValue) return;
    setFormValues((prevState) => ({
      ...prevState,
      dob: dobValue.toString().slice(0, 10)
    }));
  }, [dobValue])



  return (
    <form
      onSubmit={onSubmit}
      ref={formRef}
      className="form"
    >
      <div className="field-info bold mb-1">
        Kindly enter the {usersPronoun} Date of Birth (DOB) as it{" "
        }appears on their government-issued ID.
        <br />
        This is required for the SONA card program.
      </div>
      <DobField
        value={formVaules.dob}
        setValue={setDobValue}
        validDob={validDob}
        setValidDob={setValidDob}
      />
      <FormNavigation />
    </form>
  );
}

export default Dob;