import { Link } from "react-router-dom";

import logo from "../../../assets/vector-images/brands/sona-logo.svg";
import { rootPaths } from "../../../routes/paths";
import Styles from "./styles";



function NonAuthHeader() {
  return (
    <Styles className="root-header">
      <div className="header-wrapper">
        <Link to={rootPaths.HOMEPAGE}>
          <img src={logo} alt="" id="logo" />
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://mailchi.mp/schoolofnewafrica.com/sona-app-waitinglist"
          className="button auto-width"
        >
          Subscribe Now
        </a>
      </div>
    </Styles>
  );
}

export default NonAuthHeader;