import { Outlet } from "react-router-dom";

import { RootPage, NonAuthHeader } from "..";
import Styles from "./styles";



function NonAuthRoute() {
  return (
    <RootPage>
      <Styles className="root-page">
        <NonAuthHeader />
        <Outlet />
      </Styles>
    </RootPage>
  );
}

export default NonAuthRoute;