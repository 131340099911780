import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  .user-data {
    margin-bottom: 2rem;
    h6 {
      font-size: 10px;
      margin-bottom: 0.5rem;
    }
    .card {
      padding: 1rem;
      border: 1px solid ${colors.grey1};
      border-radius: 7px;
      background-color: ${colors.white};
      margin-bottom: 0.5rem;
      li {
        padding: 0.5rem;
        font-size: 0.8rem;
        &:not(:last-child) {
          border-bottom: 1px solid ${colors.grey1};
        }
        .title {
          color: ${colors.grey6};
        }
        .content {
          text-align: right;
        }
      }
    }
  }
`;

export default Styles;