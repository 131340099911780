import styled from "styled-components";

import { colors, resolutions } from "../../../styles";



const Styles = styled.div`
  background-color: transparent !important;
  padding: 0 !important;
  .card-links {
    gap: 2rem;
    @media(max-width: ${resolutions.tablet}) {
      gap: 1rem;
    }
    .card-link {
      padding: 2rem;
      border-radius: 30px;
      background-color: ${colors.white};
      flex-wrap: wrap;
      column-gap: 7rem;
      row-gap: 1rem;
      figure {
        gap: 1rem;
        figcaption {
          .grey-text {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`;

export default Styles;