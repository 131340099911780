import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  max-width: ${properties.maxFormWidth} !important;
  .crest-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: ${properties.maxFormWidth};
    .crest-fields {
      column-gap: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      align-items: flex-start;
      .crest.radio-button-field {
        label {
          figure {
            gap: 1rem;
            img {
              width: 50px;
              height: 90px;
              object-fit: contain;
              object-position: center;
            }
            figcaption {
              .title {
                color: ${colors.grey3};
                font-weight: 600;
              }
              .description {
                margin-top: 0.5rem;
                color: ${colors.grey6};
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
  }
`;

export default Styles;