import axios from "axios";

import { UserTree } from "./types";
import { ErrorResponse } from "../../types";



/*  ###################### */
/** ## TREE API REQUESTS # */
/*  ###################### */
const useTrees = () => {
  const getUserTrees = (params?: Record<string, string | number>) => {
    return new Promise<UserTree[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/user-trees`,
        ...(params ? { params } : {})
      }).then((res) => {
        const results = res.data.results as UserTree[];
        resolve(results);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  return {
    getUserTrees
  }
}

export default useTrees;