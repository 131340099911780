import { SelectFieldOption } from "../../components/forms/SelectField";
import { Address } from "../addresses/types";



type KYCStatus =
  | "unstarted"
  | "processing"
  | "pass"
  | "fail"
  | "autoFail"
  | "docFail"
  | "completeFail";

export type RequiredDocument =
  | "none"
  | "address"
  | "identity"
  | "addressAndIdentity";

type AcceptableDocument =
  | "drivingLicense"
  | "passport"
  | "nationalId"
  | "residencePermit"
  | "bankStatement"
  | "creditStatement"
  | "utilityBill"
  | "otherGovDocumentation";


export type Kyc = Address & {
  requiredDocument: RequiredDocument;
  documentVerified: boolean;
  docUploadResponse: string;
  autoKycVerified: boolean;
  autoKycResponse: string;
  watchListCheck: string;
  docUploadCount: number;
  autoKycCount: number;
  uk007bCheck: string;
  uk015Check: string;
  status: KYCStatus;
  createdAt: Date;
  updatedAt: Date;
}

interface KycDocumentOption extends SelectFieldOption {
  value: AcceptableDocument;
  details?: string;
}
/***************************/
/** KYC Identity Documents */
export const identityDocs: KycDocumentOption[] = [
  {
    value: "drivingLicense",
    label: "Driving License"
  },
  {
    value: "passport",
    label: "Passport"
  },
  {
    value: "nationalId",
    label: "Nationl ID Card"
  },
  {
    value: "residencePermit",
    label: "Residence Permit",
    details: "Residence Permit (Containing Date of Birth)"
  }
]

export const addressDocs: KycDocumentOption[] = [
  {
    value: "bankStatement",
    label: "Bank Statement",
    details: "Bank Statement (within the last 3 months)"
  },
  {
    value: "creditStatement",
    label: "Credit Card Statement",
    details: "Credit Card Statement (within the last 3 months)"
  },
  {
    value: "utilityBill",
    label: "Utility Bill",
    details: (
      `Utility Bill (such as water, electricity, gas, landline,${" "
      }home internet, and issued within the last 3 months)`
    )
  },
  {
    value: "otherGovDocumentation",
    label: "Government-Issued Documentation",
    details: (
      `Government-Issued Documentation (including documents from${" "
      }local authorities for address registration, tax or social${" "
      }benefits, and issued within the last 3 months)`
    )
  }
]

export const allAcceptedKycDocs = [
  ...identityDocs,
  ...addressDocs
]

export const doubleFieldDocs: AcceptableDocument[] = [
  "drivingLicense",
  "nationalId"
]