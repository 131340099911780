import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import anase_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import { securityPaths } from "../../../routes/security/paths";
import { consolePaths } from "../../../routes/_console/paths";

import { InfoPanel, Loader } from "../../../components/layout";
import { User } from "../../../requests/users/types";
import { rootPaths } from "../../../routes/paths";
import { isEmail } from "../../../utils/strings";
import useUsers from "../../../requests/users";
import Styles from "./styles";



function EmailVerified() {
  const navigate = useNavigate();
  const { getUserProfile } = useUsers();
  const [user, setUser] = useState<User>();


  useEffect(() => {
    getUserProfile()
      .then((resData) => {
        setUser(resData)
      }).catch(() => null);
  }, [])

  useEffect(() => {
    if (!user) return;
    if (!isEmail(user.username)) {
      navigate(consolePaths.DASHBOARD);
      return;
    }
    if (isEmail(user.username) && !user.emailVerified) {
      navigate(securityPaths.VERIFY_EMAIL);
    }
  }, [user])



  return (
    user ? (
      <Styles className="page-wrapper flex column center">
        <InfoPanel
          icon={anase_smile}
          title="Email Address Verified"
          description={(
            `You've successfully verified the email${" "}
            address associated with your account.`
          )}
        >
          {user.completedOnboarding ? (
            <Link
              className="button"
              to={consolePaths.DASHBOARD}
            >
              Go to your Dashboard
            </Link>
          ) : (
            <Link
              className="button"
              to={rootPaths.PROFILE_SETUP}
            >
              Begin Onboarding
            </Link>
          )}
        </InfoPanel>
      </Styles>
    ) : (
      <Loader />
    )
  );
}

export default EmailVerified;
