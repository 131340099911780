import axios from "axios";

import useAppActions from "../../redux/actions/app";
import { ErrorResponse } from "../../types";
import {
  SetNewPasswordArgs,
  UpdateProfileArgs,
  User
} from "./types";



/*  ###################### */
/** ## USER API REQUESTS # */
/*  ###################### */
const useUsers = () => {
  const { setAppLoading, saveUser } = useAppActions();


  /********************************************************/
  /** Get the logged in User's Profile (no related fiels) */
  const getUserProfile = () => {
    return new Promise<User>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/users/user-profile`
      }).then((res) => {
        const user = res.data as User;
        resolve(user);
        saveUser(user);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /************************************/
  /** Update logged-in user's details */
  const updateProfile = (data: UpdateProfileArgs) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading("Updating your Profile ...");
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/users/update-profile/`,
        method: "patch",
        data
      }).then((res) => {
        const user = res.data as User;
        const { family, heritages, address, ...rawUser } = user;
        saveUser(rawUser);
        resolve(user);
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /**************************************/
  /** Set Password if recently verified */
  const setNewPassword = (data: SetNewPasswordArgs) => {
    return new Promise<any>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/users/set-password/`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }


  return {
    updateProfile,
    getUserProfile,
    setNewPassword
  }
}

export default useUsers;