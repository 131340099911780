import { Clan } from "../../../requests/clans/types";
import Styles from "./styles";



type Props = {
  clan: Clan;
}

function ClanCrest({ clan }: Props) {
  return (
    clan.crest ? (
      <Styles className="clan-crest flex">
        <img
          src={clan.crest.url}
          className="image"
          alt=""
        />
        <figcaption className="details flex column justify-center">
          <div className="flex align-center">
            <div className="name">
              {clan.name}
            </div>
            {clan.crest ? (
              <div className="family generic-family">
                {clan.crest.name}
              </div>
            ) : null}
          </div>
        </figcaption>
      </Styles>
    ) : null
  )
}

export default ClanCrest;