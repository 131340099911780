import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../../styles";



const Styles = styled.div`
  background-color: ${colors.white};
  flex: 1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  ${snippets.scrollBar}
  position: relative;
  ul.flex.user-trees {
    z-index: 2;
    gap: 1rem;
    position: fixed;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    margin: 2rem 0;
    padding: 2rem;
    max-width: ${properties.maxFormWidth};
    box-shadow: ${properties.boxShadow};
    border-radius: 10px;
    background-color: ${colors.white};
    .user-tree {
      border: 1px solid ${colors.grey1};
      border-radius: 5px;
      padding: 1rem;
      &:hover {
        background-color: ${functions.rgba(colors.secondary, 0.1)};
      }
    }
  }

  .map-container {
    flex: 1;
    overflow: auto;
    position: relative;
    #map {
      ${snippets.absoluteCenter}
    }
  }
`;

export default Styles;