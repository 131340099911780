import axios from "axios";

import { ErrorResponse } from "../../types";
import { Address, PostcoderResult } from "./types";



const useAddresses = () => {
  /*************************************/
  /** Get the logged in User's address */
  const getUsersAddress = () => {
    return new Promise<Address>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/addresses/my-address`
      }).then((res) => {
        const address = res.data as Address;
        resolve(address);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const findAddress = (query: string) => {
    return new Promise<PostcoderResult[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/addresses/find`,
        method: "post",
        data: { query }
      }).then((res) => {
        const results = res.data as PostcoderResult[];
        resolve(results);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  return {
    getUsersAddress,
    findAddress
  }
}

export default useAddresses;