import axios from "axios";

import { ErrorResponse } from "../../types";
import { Kyc } from "./types";



/*  ##################### */
/** ## KYC API REQUESTS # */
/*  ##################### */
const useKyc = () => {
  /***************************************/
  /** Try to verify user's details in W2 */
  const autoKyc = () => {
    return new Promise<Kyc | null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/kyc/auto`
      }).then((res) => {
        const resData = res.data;
        if (resData) resolve(resData as Kyc);
        else resolve(null);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  /*************************************/
  /** Get KYC Status of logged-in user */
  const getKycStatus = () => {
    return new Promise<Kyc | null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/kyc/`
      }).then((res) => {
        const resData = res.data;
        if (resData) resolve(resData as Kyc);
        else resolve(null);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  /************************************************/
  /** Document Upload for Manual KYC Verification */
  const uploadDocument = (data: FormData) => {
    return new Promise<null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/kyc/upload-document`,
        method: "post",
        data
      }).then(() => {
        resolve(null);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    autoKyc,
    getKycStatus,
    uploadDocument
  }
}

export default useKyc;