import { ChangeEvent, FormEvent, MouseEvent } from "react";

import { validateFormData } from "./validation";
import { FormProps } from "./types";



const useFormUtils = (
  props: FormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) => {
  const { onSubmit, setValues, values } = props;
  /************************************/
  /** Validate Form before submission */
  const validateForm = (event: MouseEvent | FormEvent) => {
    validateFormData({
      event,
      formRef: ref as React.RefObject<HTMLFormElement> | null
    }).then((e) => {
      onSubmit(e);
    }).catch(() => null);
  }

  /***************************/
  /** Handles string input for:
   *  TextField,
   *  TextAreaField */
  const stringChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    maxLength?: number
  ) => {
    const { name, value } = e.target;
    if (maxLength && value.toString().length > maxLength) {
      return;
    }
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  /***************************/
  /** Handles onChange for:
   *  TextEditor */
  const editorChange = (name: string, value: string) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  /***************************/
  /** Handles select input for:
   *  SelectField */
  const selectChange = (
    event: ChangeEvent<HTMLSelectElement>, options: any[]
  ) => {
    const { name, value }: any = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  /***************************/
  /** Handles onChange for:
   *  Checkbox */
  const checkboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const oldValue = values[name];
    setValues((prevState) => ({
      ...prevState,
      [name]: !oldValue ?? false
    }));
  }


  return {
    validateForm,
    stringChange,
    editorChange,
    selectChange,
    checkboxChange
  }
}

export default useFormUtils;