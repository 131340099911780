import DownloadApp from "../../Download/DownloadApp";
import Styles from "./styles";



function Download() {
  return (
    <Styles>
      <h1 className="page-title">
        Download
      </h1>
      <DownloadApp />
    </Styles>
  );
}

export default Download;