import { ReactNode, forwardRef, Fragment } from "react";

import RadioButtonField from "../RadioButtonField";
import { FieldWrapper } from "./FieldWrapper";
import TextAreaField from "../TextAreaField";
import PasswordField from "../PasswordField";
import SelectField from "../SelectField";
import FileField from "../FileField";
import TextField from "../TextField";
import { FormProps } from "./types";
import useFormUtils from "./utils";
import Styles from "./styles";



const Form = forwardRef<HTMLFormElement, FormProps>((
  props: FormProps,
  ref
) => {
  const {
    fields, values, children, className, setValues,
  } = props
  const {
    validateForm, stringChange, selectChange,
  } = useFormUtils(props, ref);


  /***********************************/
  /** Iterate and render form fields */
  const renderFormFields = () => {
    const groupClasses: string[] = Array.from(
      new Set(fields.map((field) => field.groupClass || ""))
    );
    const fieldGroups: Record<string, ReactNode[]> = {};
    groupClasses.forEach((groupClass) => {
      fieldGroups[groupClass] = []
    })
    fields.forEach((field, index) => {
      const { groupClass, ...otherAttrs } = field;
      let element: ReactNode = null;
      switch (field.type) {
        case "text":
        case "email":
        case "number":
          element = (
            <FieldWrapper field={field} key={index}>
              <TextField
                {...otherAttrs}
                value={values[field.name]?.toString() || ""}
                onChange={stringChange}
                autoComplete={field.autoComplete ?? "off"}
                placeholder={field.placeholder || field.label}
                required={field.required ?? true}
              />
            </FieldWrapper>
          );
          break;
        case "password":
          element = (
            <FieldWrapper field={field} key={index}>
              <PasswordField
                {...otherAttrs}
                value={values[field.name]?.toString() || ""}
                onChange={stringChange}
                placeholder={field.placeholder || field.label}
                required={field.required ?? true}
              />
            </FieldWrapper>
          );
          break;
        case "textarea":
          element = (
            <FieldWrapper field={field} key={index}>
              <TextAreaField
                maxLength={field.maxLength}
                name={field.name}
                value={values[field.name]?.toString() || ""}
                onChange={(e) => stringChange(e, field.maxLength)}
                placeholder={field.placeholder || field.label}
                required={field.required ?? true}
              />
            </FieldWrapper>
          );
          break;
        case "select":
          element = (
            <FieldWrapper field={field} key={index}>
              <SelectField
                {...otherAttrs}
                value={values[field.name]?.toString() || ""}
                onChange={(e) => selectChange(e, field.options ?? [])}
                autoComplete={field.autoComplete ?? "off"}
                required={field.required ?? true}
              />
            </FieldWrapper>
          );
          break;
        case "radio":
          element = (
            <FieldWrapper field={field} key={index}>
              <RadioButtonField
                {...otherAttrs}
                value={field.value ?? ""}
                selectedValue={values[field.name] ?? ""}
                onChange={stringChange}
                required={field.required ?? true}
              />
            </FieldWrapper>
          );
          break;
        case "file":
          element = (
            <FieldWrapper field={field} key={index}>
              <FileField
                {...otherAttrs}
                onChange={(name, file) => {
                  if (field.multiple) {
                    setValues((prevState) => ({
                      ...prevState,
                      [name]: [
                        ...(values[name] ? values[name] : []),
                        ...Array.from(file as FileList),
                      ]
                    }));
                  } else {
                    setValues((prevState) => ({
                      ...prevState,
                      [name]: file,
                    }));
                  }
                }}
              />
            </FieldWrapper>
          );
          break;
        case "react-node":
          element = field.reactNode;
          break;
        default:
          return null;
      }
      fieldGroups[groupClass].push(element)
    });
    const formFields = Object.entries(fieldGroups).map((
      [groupClass, elements], index
    ) => (
      <fieldset className={groupClass} key={index}>
        {elements.map((element, index) =>
          <Fragment key={index}>
            {element}
          </Fragment>
        )}
      </fieldset>
    ));

    return formFields;
  }



  return (
    <Styles
      ref={ref}
      noValidate
      className={`form ${className ? className : ""}`}
      onSubmit={validateForm}
    >
      {renderFormFields()}
      {children}
    </Styles>
  );
});

export default Form;