import styled from "styled-components";

import { colors, properties, resolutions } from "../../../styles";



const Styles = styled.div`
  header {
    text-align: center;
    .top-row {
      position: relative;
      .back-icon {
        gap: 0;
        position: absolute;
        left: 0;
        top: 0;
        svg {
          path {
            stroke: ${colors.grey3};;
          }
        }
        .text {
          color: ${colors.grey3};
          font-weight: 600;
          @media(max-width: ${resolutions.tabletLarge}) {
            display: none;
          }
        }
      }
      .main-title {
        font-size: 2rem;
      }
    }
    .regions {
      margin-top: 1rem;
    }
    .region-link {
      font-size: 0.8rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      border: 2px solid ${colors.grey6};
      width: 7rem;
      background-color: transparent;
      transition: background-color  ${properties.transition};
      &.active {
        border: ${colors.primary};
        background-color: ${colors.primary};
        color: ${colors.white};
      }
    }
  }
`;

export default Styles;