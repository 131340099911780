import { PropsWithChildren } from "react";

import LabelTooltip from "../LabelToolTip";
import { FormField } from "./types";



export interface Props extends PropsWithChildren {
  field: FormField;
}

/*************************************************/
/** Wrapper with semantic label for a form field */
export function FieldWrapper({
  field,
  children
}: Props) {
  const { name, label, className } = field;

  return (
    <div className={`field-column ${className ? className : ""}`}>
      {label !== "" ? (
        <LabelTooltip htmlFor={name}>
          {label}
          {(field.required !== null && field.required === false)
            ? "" : "*"}
        </LabelTooltip>
      ) : null}
      {children}
    </div>
  );
}