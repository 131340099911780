import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  img {
    width: 15px;
    height: 15px;
  }
  .pop-up {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: ${colors.white};
    width: max-content;
    box-shadow: ${properties.boxShadow};
    border: 1px solid ${colors.grey1};
    font-size: 0.8rem;
  }
`;

export default Styles;