import { profileSetupPaths } from "./paths";
import { RouteType } from "../types";
import {
  NameEntry,
  DobEntry,
  AddressEntry,
  CountryEntry,
  SaveCard,
  KycCheck,
  KycUploadDocument,
  KycUploadSuccess,
  KycVerificationPending,
  KycVerifcationSuccess,
  KycVerifcationFailed,
  AvatarSelect,
  Heritages,
  MissionCompleted,
  SecondaryChecklist,
  NicknameEntry,
  KycUploadInstructions,
  ProfileSummary,
  KYCCompleteFail
} from "../../pages/ProfileSetup/pages";



/******************************/
/** Primary Onboarding Routes */
const primaryOnboarding: RouteType[] = [
  {
    path: profileSetupPaths.COUNTRY_ENTRY,
    element: <CountryEntry />
  },
  {
    path: profileSetupPaths.SAVE_CARD,
    element: <SaveCard />
  },
  {
    path: profileSetupPaths.NAME_ENTRY,
    element: <NameEntry />
  },
  {
    path: profileSetupPaths.NICKNAME_ENTRY,
    element: <NicknameEntry />
  },
  {
    path: profileSetupPaths.DOB_ENTRY,
    element: <DobEntry />
  },
  {
    path: profileSetupPaths.ADDRESS_ENTRY,
    element: <AddressEntry />
  },
  {
    path: profileSetupPaths.PROFILE_SUMMARY,
    element: <ProfileSummary />
  }
]

/********************************/
/** Secondary Onboarding Routes */
const secondaryOnboarding: RouteType[] = [
  {
    path: profileSetupPaths.SECONDARY_CHECKLIST,
    element: <SecondaryChecklist />
  },
  {
    path: profileSetupPaths.AVATAR_SELECT,
    element: <AvatarSelect />
  },
  {
    path: profileSetupPaths.HERITAGES,
    element: <Heritages />
  },
  {
    path: profileSetupPaths.MISSION_COMPLETED,
    element: <MissionCompleted />
  }
]

/***************/
/** KYC Routes */
const kyc: RouteType[] = [
  {
    path: profileSetupPaths.KYC_CHECK,
    element: <KycCheck />
  },
  {
    path: profileSetupPaths.KYC_UPLOAD_DOCUMENT,
    element: <KycUploadDocument />
  },
  {
    path: profileSetupPaths.KYC_UPLOAD_SUCCESS,
    element: <KycUploadSuccess />
  },
  {
    path: profileSetupPaths.KYC_UPLOAD_INSTRUCTIONS,
    element: <KycUploadInstructions />
  },
  {
    path: profileSetupPaths.KYC_VERIFICATION_PENDING,
    element: <KycVerificationPending />
  },
  {
    path: profileSetupPaths.KYC_VERIFICATION_SUCCESS,
    element: <KycVerifcationSuccess />
  },
  {
    path: profileSetupPaths.KYC_VERIFICATION_FAILED,
    element: <KycVerifcationFailed />
  },
  {
    path: profileSetupPaths.KYC_COMPLETE_FAIL,
    element: <KYCCompleteFail />
  }
]



export const profileSetupRoutes: RouteType[] = [
  ...primaryOnboarding,
  ...secondaryOnboarding,
  ...kyc
]