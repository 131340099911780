import { subscriptionPaths } from "./paths";
import { RouteType } from "../types";
import {
  SubscriptionFailed,
  SubscriptionList,
  SubscriptionSuccess
} from "../../pages/Subscriptions/pages";



/************************/
/** Subscription Routes */
export const subscriptionRoutes: RouteType[] = [
  {
    path: subscriptionPaths.LIST,
    element: <SubscriptionList />
  },
  {
    path: subscriptionPaths.SUCCESS,
    element: <SubscriptionSuccess />
  },
  {
    path: subscriptionPaths.FAILED,
    element: <SubscriptionFailed />
  }
]