export const subscriptionRoot = "/subscriptions";



/***********************/
/** Subscription Paths */
export const subscriptionPaths = {
  LIST: `${subscriptionRoot}/`,
  SUCCESS: `${subscriptionRoot}/success/`,
  FAILED: `${subscriptionRoot}/failed/`
}