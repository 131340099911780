import styled from "styled-components";

import { snippets } from "../../../styles";



const Styles = styled.div`
  ${snippets.scrollBar}
  max-width: 400px;
  .country-list {
    margin: 1rem 0;
    max-height: 18rem;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 0.5rem;
    margin-right: -0.5rem;
    .radio-button-field {
      label {
        .flag {
          font-size: 1.1rem;
        }
      }
    }
  }
`;

export default Styles;