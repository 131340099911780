import { Outlet } from "react-router-dom";

import { RootPage } from "..";
import Styles from "./styles";



function OnboardingMissionRoute() {
  return (
    <RootPage>
      <Styles className="root-page">
        <Outlet />
      </Styles>
    </RootPage>
  );
}

export default OnboardingMissionRoute;