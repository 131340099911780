import { useCallback, useEffect, useState } from "react";

import { Loader, VideoPlayer } from "../../../components/layout";
import { Mission } from "../../../requests/missions/types";
import useMissions from "../../../requests/missions";
import useOnboardingLessonUtils from "./utils";
import Styles from "./styles";



function OnboardingLesson() {
  const { onStart, onEnded, playing } = useOnboardingLessonUtils();
  const { getOnboardingMission } = useMissions();
  const [mission, setMission] = useState<Mission>();


  /*******************************/
  /** Get the Onboarding Mission */
  const retrieveOnboardingMission = useCallback(() => {
    getOnboardingMission()
      .then((resData) => {
        setMission(resData);
      }).catch(() => null)
  }, [])


  useEffect(() => {
    retrieveOnboardingMission()
  }, [retrieveOnboardingMission])



  return (
    <Styles className="root-content">
      <div className="page-wrapper">
        {mission ? (
          <>
            <VideoPlayer
              playing={playing}
              onReady={onStart}
              onEnded={onEnded}
              url={
                mission.fallbackVideoUrl
                  ? mission.fallbackVideoUrl
                  : mission.videoUrl
              }
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Styles>
  );
}

export default OnboardingLesson;