import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MouseEvent, useEffect, useState } from "react";

import chevron_bottom from "../../../assets/vector-images/generic/chevron-bottom.svg";
import { FamilyCrest, InfoPanel, Loader } from "../../../components/layout";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import { FamilyInvite } from "../../../requests/familyInvites/types";
import useFamilyInvites from "../../../requests/familyInvites";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { familyPaths } from "../../../routes/family/paths";
import useFamilies from "../../../requests/families";
import { URLParams } from "../../../constants";



function AcceptInvite() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { familyInviteId } = useParams<URLParams>();
  const { getFamilyInvite } = useFamilyInvites();
  const { getMyFamily } = useFamilies();
  const [familyInvite, setFamilyInvite] = useState<FamilyInvite>();
  const { setAppModal, closeModal } = useAppModal();


  /****************************************/
  /** Confirm Acceptance of Family Invite */
  const confirmAcceptance = (e: MouseEvent) => {
    e.preventDefault();
    if (!familyInvite) return;
    getMyFamily()
      .then((family) => {
        // Confirmation dialog for family switch
        if (family && family.id !== familyInvite.familyId) {
          setAppModal({
            title: "Leave your current Family?",
            children: (
              <div className="al-center">
                <div className="flex column center mb-2">
                  <FamilyCrest family={family} />
                  <img src={chevron_bottom} alt="" />
                  <FamilyCrest family={familyInvite.family} />
                </div>
                <div className="center">
                  Accepting this invite would move you from your{" "}
                  current family to this family.
                </div>
                <div className="bold mt-1">
                  Do you want to accept this invite?
                </div>
              </div>
            ),
            controls: (
              <div className="modal-controls">
                <button
                  className="button white-primary"
                  onClick={() => {
                    closeModal();
                    navigate(familyPaths.detail(pathname));
                  }}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={() => {
                    closeModal();
                    navigate(nonAuthPaths.checkInvite(familyInvite.id))
                  }}
                >
                  Yes, Accept Family Invite
                </button>
              </div>
            )
          });
          return;
        }
        navigate(nonAuthPaths.checkInvite(familyInvite.id));
      }).catch(() => null);
  }

  useEffect(() => {
    if (!familyInviteId) return;
    getFamilyInvite(familyInviteId)
      .then((invite) => {
        setFamilyInvite(invite);
      }).catch(() => null)
  }, [familyInviteId])



  return (
    !familyInvite ? (
      <Loader message="Accessing Invitation" />
    ) : (
      <div className="bg-action-pose faded">
        <div className="page-wrapper centered">
          <InfoPanel
            title={`Join ${familyInvite.family.name} Family`}
            description={(
              `You've been invited to join this family on the ${" "}
              School of New Africa (SONA)`
            )}
          >
            <button
              className="button"
              onClick={confirmAcceptance}
            >
              Accept Invite
            </button>
          </InfoPanel>
        </div>
      </div>

    )
  );
}

export default AcceptInvite;