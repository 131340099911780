import { useRef } from "react";

import close_icon from "../../../assets/vector-images/generic/close.svg";
import copy_icon from "../../../assets/vector-images/generic/copy.svg";
import { useHiddenOutside } from "../../../utils/components";
import Styles from "./styles";



type Props = {
  text: string;
}

function CopyToClipboardButton({ text }: Props) {
  const tooltip = useRef<HTMLDivElement>(null);
  const {
    visible: popUp,
    setVisible: setPopUp
  } = useHiddenOutside(tooltip);


  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setPopUp(true);
    } catch (error) { }
  }



  return (
    <Styles className="clipboard-copy" ref={tooltip}>
      <button
        onClick={handleCopy}
        type="button"
      >
        <img src={copy_icon} alt="" />
      </button>
      {popUp ? (
        <div className="pop-up flex align-center justify-between">
          <div className="grey-text">
            Link copied to clipboard!
          </div>
          <button
            className="close-icon"
            onClick={() => setPopUp(false)}
          >
            <img src={close_icon} alt="" />
          </button>
        </div>
      ) : null}
    </Styles>
  );
}

export default CopyToClipboardButton;
