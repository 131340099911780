import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../requests/users/types";
import useUsers from "../../../requests/users";
import Form from "../../../components/forms/Form";
import { ProfileSetupContext } from "..";



function NameEntry() {
  const navigate = useNavigate();
  const { updateProfile } = useUsers();
  const formRef = useRef<HTMLFormElement>(null);
  const { user } = useOutletContext<ProfileSetupContext>();
  const [formValues, setFormValues] = useState<FormValues>({});


  /**********************/
  /** Save Account Data */
  const saveData = () => {
    updateProfile(formValues as UpdateProfileArgs)
      .then(() => {
        navigate(profileSetupPaths.DOB_ENTRY);
      }).catch(() => null);
  }

  const formFields: FormField[] = [
    {
      type: "text",
      name: "nickName",
      label: "Nickname (Display Name)",
      placeholder: "Nickname",
      groupClass: "field-row 2"
    }
  ]


  useEffect(() => {
    if (!user) return;
    const { nickName } = user;
    setFormValues((prevState) => ({
      ...prevState,
      nickName
    }));
  }, [user])



  return (
    <div className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Enter your Nickname
        </h3>
        <div className="subtitle">
          This would be your display name on in the SONA community
        </div>
      </header>

      {/* Name Form */}
      <section>
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveData}
          ref={formRef}
        >
          <button
            className="button mlr-auto submit"
            type="submit"
          >
            Continue
          </button>
        </Form>
      </section>
    </div>
  );
}

export default NameEntry;