import { Region } from "../requests/trees/types";



export const getVerboseRegionName = (region: Region) => {
  switch (region) {
    case "prehistory":
      return "Pre-History";
    case "ancient":
      return "Ancient";
    case "medieval":
      return "Medieval";
    case "colonial":
      return "Colonial";
    case "modern":
      return "Modern";
    default:
      return "Pre-History";
  }
}