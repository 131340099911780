import axios from "axios";

import { ErrorResponse } from "../../types";
import { Mission, MissionListRes } from "./types";



/*  ######################### */
/** ## MISSION API REQUESTS # */
/*  ######################### */
const useMissions = () => {
  /*******************************/
  /** Get the Onboarding Mission */
  const getOnboardingMission = () => {
    return new Promise<Mission>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/missions/onboarding`
      }).then((res) => {
        resolve(res.data as Mission);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  /**********************/
  /** User Mission List */
  const listMissions = (params: Record<string, string | number>) => {
    return new Promise<MissionListRes>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_CORE_API_URL
          }/v1/user-missions/`,
        params
      }).then((res) => {
        resolve(res.data as MissionListRes);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    getOnboardingMission,
    listMissions
  }
}

export default useMissions;