import { MouseEvent } from "react";

import { MissionItem, missionItems } from "./utils";
import Styles from "./styles";



function MissionCompleted() {
  const viewMissionItem = (e: MouseEvent, missionItem: MissionItem) => {
    e.preventDefault();
    // setAppModal(missionItem)
  }


  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <img className="head-icon" src="" alt="" />
        <h3 className="title">
          Mission Items Unlocked
        </h3>
      </header>
      <section className="mission-items">
        <ul className="item-list">
          {missionItems.map((item, index) =>
            <li
              key={`${item.name}-${index}`}
              className="mission-item"
            >
              <button className="flex align-center"
                onClick={(e) => viewMissionItem(e, item)}
              >
                <img src={item.icon} alt="" />
                <div className="name">
                  {item.name}
                </div>
              </button>
            </li>
          )}
        </ul>
      </section>
    </Styles>
  );
}

export default MissionCompleted;