import { Link } from "react-router-dom";

import ananse_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import { InfoPanel } from "../../../components/layout";
import { rootPaths } from "../../../routes/paths";



type Props = {
  familyName: string;
}

function AcceptedInvite({ familyName }: Props) {
  return (
    <InfoPanel
      icon={ananse_smile}
      title={`Joined ${familyName} Family!`}
      description={(
        <>
          <div>
            You've successfully joined this family.
          </div>
          <div>
            Go on quests together on the SONA App!
          </div>
        </>
      )}
    >
      <Link
        to={rootPaths.HOMEPAGE}
        className="button"
      >
        Back Home
      </Link>
    </InfoPanel>
  );
}

export default AcceptedInvite;