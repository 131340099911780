import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import pending from "../../../../assets/vector-images/panel/task-list.svg";
import { profileSetupPaths } from "../../../../routes/profileSetup/paths";
import useAppActions from "../../../../redux/actions/app";
import { InfoPanel } from "../../../../components/layout";
import { rootPaths } from "../../../../routes/paths";
import useKyc from "../../../../requests/kyc";



function KycVerificationPending() {
  const navigate = useNavigate();
  const { getKycStatus } = useKyc();
  const { setAppLoading } = useAppActions();

  const redirectToHome = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(rootPaths.HOMEPAGE);
  }


  useEffect(() => {
    setAppLoading(true);
    getKycStatus()
      .then((kyc) => {
        if (!kyc) {
          // Auto KYC not performed yet
          navigate(profileSetupPaths.NAME_ENTRY);
          return;
        } else {
          switch (kyc.status) {
            // Auto KYC not performed yet
            case "unstarted":
              navigate(profileSetupPaths.NAME_ENTRY);
              return;

            // KYC Check was previously performed and pending
            case "processing":
              return;

            // Auto KYC was performed and passed
            case "pass":
              navigate(profileSetupPaths.KYC_VERIFICATION_SUCCESS);
              return;

            // Auto KYC was performed but failed
            case "fail":
            case "autoFail":
              navigate(profileSetupPaths.KYC_UPLOAD_DOCUMENT);
              return;

            // Document Upload was previously performed but failed
            case "docFail":
              if (kyc.docUploadCount <= 3) {
                navigate(profileSetupPaths.KYC_UPLOAD_DOCUMENT);
                return;
              } else {
                navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
                return;
              }

            // Exhausted Doc Upload tries
            case "completeFail":
              navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
              return;
            default:
              return;
          }
        }
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }, [])


  return (
    <div className="page-wrapper">
      <InfoPanel
        icon={pending}
        title="Verification Ongoing"
        description={(
          <div>
            We are reviewing your information{" "}
            and we hope to have you verified as soon as possible.
            <br />
            Please check back later.
            <br /><br />
            Verification can take up to 24 hours
          </div>
        )}
      >
        <button
          onClick={redirectToHome}
          className="button white-black"
        >
          Okay
        </button>
      </InfoPanel>
    </div>
  );
}

export default KycVerificationPending;