import { Outlet, useOutletContext } from "react-router-dom";

import { ConsoleContext } from "..";
import Styles from "./styles";



export type FamilyContext = ConsoleContext;
function Family() {
  const consoleContext = useOutletContext<FamilyContext>();

  return (
    <Styles>
      <h1 className="page-title">
        Family
      </h1>
      <Outlet
        context={{
          ...consoleContext
        }}
      />
    </Styles>
  );
}

export default Family;