import { Link } from "react-router-dom";

import id_success from
  "../../../../assets/vector-images/panel/id-card-success.svg";
import { InfoPanel } from "../../../../components/layout";
import { rootPaths } from "../../../../routes/paths";



function UploadSuccess() {
  return (
    <div className="page-wrapper">
      <InfoPanel
        icon={id_success}
        title="Verification Document Uploaded"
        description={(
          `You've successfully uploaded your document!${" "}
          We will validate this and give you feedback within 24 hours.`
        )}
      >
        <Link
          to={rootPaths.HOMEPAGE}
          className="button"
        >
          Ok, Great!
        </Link>
      </InfoPanel>
    </div>
  );
}

export default UploadSuccess;