import styled from "styled-components";

import VerifyEmailStyles from "../VerifyEmail/styles";



const Styles = styled(VerifyEmailStyles)`
  .info-heading {
    img {
      width: 110px;
    }
  }
`;

export default Styles;