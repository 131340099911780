import { Dispatch, SetStateAction, useMemo } from "react";
import countries from "country-list";

import { getFlagEmoji } from "../../../utils/images";
import RadioButtonField from "../RadioButtonField";
import LabelTooltip from "../LabelToolTip";
import TextField from "../TextField";
import Styles from "./styles";



type Country = {
  code: string;
  name: string;
}

interface Props extends React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement
> {
  country: string;
  countryCh: (event: React.ChangeEvent<HTMLInputElement>) => void;
  search: string;
  searchCh: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSearch?: Dispatch<SetStateAction<string>>;
}

function CountrySelect({
  country,
  countryCh,
  search,
  searchCh
}: Props) {
  const countryData: Country[] = useMemo(() => (
    countries.getData().sort(
      (a, b) => a.name.localeCompare(b.name)
    )
  ), []);

  const filteredCountries = useMemo(() => (
    search ? countryData.filter((country) =>
      country.name.toLowerCase().includes(search.toLowerCase())
    ) : countryData
  ), [search, countryData]);



  return (
    <Styles className="country-select">
      <div className="field-column">
        <LabelTooltip htmlFor="country-search">
          Search Country
        </LabelTooltip>
        <TextField
          name="country-ssearch"
          value={search}
          onChange={searchCh}
          required={false}
          placeholder="E.g United Kingdom"
        />
      </div>
      <ul className="country-list">
        {filteredCountries.map((c) => (
          <RadioButtonField
            key={c.code}
            name="country"
            value={c.code}
            selectedValue={country}
            onChange={countryCh}
          >
            <div className="flex align-center">
              <div className="flag">
                {getFlagEmoji(c.code)}
              </div>
              <div className="name">
                {c.name}
              </div>
            </div>
          </RadioButtonField>
        ))}
      </ul>
    </Styles>
  );
}

export default CountrySelect;