import { Link } from "react-router-dom";

import broken_link from "../../../assets/vector-images/panel/broken-link.svg";
import { InfoPanel } from "../../../components/layout";
import { rootPaths } from "../../../routes/paths";



function UnauthorizedInvite() {
  return (
    <InfoPanel
      icon={broken_link}
      title="Not Your Invitation Link"
      description={(
        <>
          <div>
            Looks like this invitation link is not tied to your account.
          </div>
          <div>
            Ask the Parent / Family Admin to send{" "}
            another link tied to your account.
          </div>
        </>
      )}
    >
      <Link
        to={rootPaths.HOMEPAGE}
        className="button white-black"
      >
        Back Home
      </Link>
    </InfoPanel>
  )
}

export default UnauthorizedInvite;