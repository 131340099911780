import styled from "styled-components";



const Styles = styled.div`
  .info-heading {
    img {
      margin-bottom: 1rem;
    }
  }
`;

export default Styles;