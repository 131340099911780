import { Link } from "react-router-dom";

import seats from "../../../assets/vector-images/panel/seats.svg";
import { InfoPanel } from "../../../components/layout";
import { rootPaths } from "../../../routes/paths";



function PreviouslyAccepted() {
  return (
    <InfoPanel
      icon={seats}
      title="Part of the Family!"
      description={(
        <>
          <div>
            You're already in this Family so there's nothing else to do.
          </div>
          <div>
            Wrong Family Invite link? Reach out to the {" "}
            Parent / Family Admin who invited you.
          </div>
        </>
      )}
    >
      <Link
        to={rootPaths.HOMEPAGE}
        className="button white-black"
      >
        Back Home
      </Link>
    </InfoPanel>
  );
}

export default PreviouslyAccepted;