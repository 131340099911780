import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

import { FormValues, FormField } from "../../../components/forms/Form/types";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { SignUpData } from "../../../requests/auth/types";
import { PrivacyTerms } from "../../../components/legal";
import { rootPaths } from "../../../routes/paths";
import Form from "../../../components/forms/Form";
import useAuth from "../../../requests/auth";
import Styles from "./styles";



function SignUpFamilyInvite() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { signUp } = useAuth();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const searchParams = new URLSearchParams(location.search);
  const familyName = searchParams.get("familyName");
  const inviteCode = searchParams.get("inviteCode");


  /****************/
  /** Submit Data */
  const submitData = () => {
    const data = formValues as SignUpData;
    signUp(data)
      .then(() => {
        if (state && state.previousPath)
          navigate(state.previousPath);
        else
          navigate(rootPaths.HOMEPAGE);
      }).catch(() => null);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "username",
      label: "Email or Username",
      placeholder: "you@example.com or your-username",
      autoComplete: "off",
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Your secure password",
      autoComplete: "new-password",
      username: formValues.username,
      groupClass: "field-group"
    }
  ]

  useEffect(() => {
    if (inviteCode) {
      setFormValues((prevState) => ({
        ...prevState,
        inviteCode
      }));
    }
  }, [inviteCode])


  return (
    <Styles className="root-content bg-action-pose">
      <div className="page-wrapper field-width centered">
        {/* Heading */}
        <header>
          <h3 className="title">
            Join {familyName} Family
          </h3>
          <div className="description">
            You've been invited to join this family on the{" "}
            School of New Africa (SONA)
          </div>
        </header>

        {/* Sign-Up Form */}
        <section>
          <Form
            fields={formFields}
            values={formValues}
            setValues={setFormValues}
            onSubmit={submitData}
            ref={formRef}
          >
            <button
              className="button submit"
              type="submit"
            >
              Sign Up & Accept Invite
            </button>
          </Form>
        </section>

        {/* Alternate Authentication (Sign In) */}
        <section className="alt-entry flex center">
          <div>Already have an account?</div>
          <Link
            to={nonAuthPaths.SIGN_IN}
            className="link underline black"
          >
            Sign In
          </Link>
        </section>

        {/* Privacy & Terms */}
        <PrivacyTerms />
      </div>
    </Styles>

  )
}

export default SignUpFamilyInvite;