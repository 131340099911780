import axios from "axios";

import { ErrorResponse } from "../../types";
import { Crest } from "./types";



/*  ####################### */
/** ## CREST API REQUESTS # */
/*  ####################### */
const useCrests = () => {

  /****************/
  /** List Crests */
  const listCrests = (params?: Record<string, string>) => {
    return new Promise<Crest[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/crests/`,
        ...(params ? {
          params
        } : {})
      }).then((res) => {
        resolve(res.data as Crest[]);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /**************/
  /** Get Crest */
  const getCrest = (id: string | number) => {
    return new Promise<Crest>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/crests/${id}`,
      }).then((res) => {
        resolve(res.data as Crest);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listCrests,
    getCrest
  }
}

export default useCrests;