import { useState, useEffect } from "react";



type PasswordStrengthState = {
  hasNumber: boolean;
  hasLowercase: boolean;
  hasUppercase: boolean;
  has8Chars: boolean;
  notContainUsername: boolean;
}

type Props = {
  value: string;
  username?: string | null;
}

function PasswordStrength({ value, username }: Props) {
  const [strength, setStrength] = useState<PasswordStrengthState>({
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false,
    has8Chars: false,
    notContainUsername: true
  });


  useEffect(() => {
    const hasNumber = /[0-9]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasUppercase = /[A-Z]/.test(value);
    const has8Chars = value.length >= 8;
    const notContainUsername = username
      ? !value.toLocaleLowerCase().includes(username.toLocaleLowerCase())
      : true;

    const newStrength = {
      hasNumber,
      hasLowercase,
      hasUppercase,
      has8Chars,
      notContainUsername
    };
    setStrength(newStrength);
  }, [value]);



  return (
    <div className={`password-strength`}>
      <p className="title">Your password must:</p>
      <ul className="parameters flex column">
        <li className={`param ${strength.notContainUsername ? "valid" : ""}`}>
          Not contain your Username or Email
        </li>
        <li className={`param ${strength.has8Chars ? "valid" : ""}`}>
          Be at least 8 characters
        </li>
        <li className={`param ${strength.hasNumber ? "valid" : ""}`}>
          Include at least one number
        </li>
        <li className={`param ${strength.hasUppercase ? "valid" : ""}`}>
          Include at least one uppercase letter
        </li>
        <li className={`param ${strength.hasLowercase ? "valid" : ""}`}>
          Include at least one lowercase letter
        </li>
      </ul>
    </div>
  );
}

export default PasswordStrength;