import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import Styles from "./styles";



// Context (Props) passed down to Child Pages (<Outlet/>)
export type DownloadContext = AuthRouteContext;

function Download() {
  const downloadContext = useOutletContext<DownloadContext>();



  return (
    <Styles className="root-content bg-action-pose faded">
      <Outlet
        context={{
          ...downloadContext
        }} />
    </Styles>
  );
}

export default Download;