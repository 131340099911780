import styled from "styled-components";
import { colors, snippets } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  position: relative;
  iframe {
    ${snippets.absoluteCenter}
  }
`;

export default Styles;