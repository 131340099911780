import { useNavigate } from "react-router-dom";
import { useState } from "react";

import FormSteps, { useFormNavigation } from "../../forms/FormSteps";
import { missionPaths } from "../../../routes/missions/paths";
import { Quiz } from "../../../requests/missions/types";
import { FormStep } from "../../forms/FormSteps/types";
import { FormValues } from "../../forms/Form/types";
import MissionQuiz from "../MissionQuiz";



type Props = {
  quizzes: Quiz[];
  lessonLink: string;
  completionLink?: string;
}

function Mission({
  quizzes,
  lessonLink
}: Props) {
  const navigate = useNavigate();
  const {
    currentStep, FormNavigation, nextStep, previousStep
  } = useFormNavigation();
  const [formValues, setFormValues] = useState<FormValues>({});


  const submitMission = () => {
    let correctAnswers = 0;
    quizzes.forEach((quiz) => {
      const correctAns = quiz.options.find(opt => opt.isCorrect);
      if (!correctAns) return;
      if (parseInt(formValues[quiz.id]) === correctAns.id) {
        correctAnswers++;
      }
    });
    navigate(`${missionPaths.ONBOARDING_RESULT
      }?correct=${correctAnswers}`);
  }

  const formSteps: FormStep[] = quizzes.map(
    (quiz, index) => ({
      title: "",
      content: (
        <MissionQuiz
          FormNavigation={FormNavigation}
          previousStep={previousStep}
          nextStep={nextStep}
          quiz={quiz}
          quizIndex={index}
          quizzesLength={quizzes.length}
          values={formValues}
          setValues={setFormValues}
          onSubmit={submitMission}
          lessonLink={lessonLink}
        />
      )
    })
  );


  return (
    <FormSteps
      currentStep={currentStep}
      steps={formSteps}
      progressType="none"
    />
  );
}

export default Mission;