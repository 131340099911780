import { Outlet, useOutletContext } from "react-router-dom";
import { useEffect } from "react";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import Styles from "./styles";



// Context (Props) passed down to Child Pages (<Outlet/>)
export type FamilyContext = AuthRouteContext;
function Family() {
  const familyContext = useOutletContext<FamilyContext>();

  useEffect(() => {
    // Check for family subscription before viewing any page
  }, [])



  return (
    <Styles className="root-content bg-action-pose faded">
      <Outlet context={familyContext} />
    </Styles>
  );
}

export default Family;