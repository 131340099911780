import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../../styles";



const Styles = styled.div`
  max-width: ${properties.maxFieldWidth} !important;
  .user-avatar {
    margin: 0 auto;
    justify-content: center;
  }

  .selected-heritages {
    margin-top: 2rem;
    .title {
      margin-bottom: 0.5rem;
      color: ${colors.grey2};
    }
    .heritage {
      padding: 1rem;
      border: 1px solid ${colors.grey1};
      border-radius: 7px;
      background-color: ${colors.white};
    }
  }

  .choose-heritage {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 2rem;
    width: fit-content !important;
    figure {
      border: 1px dashed ${colors.primary};
      background-color: ${colors.white};
      padding: 1rem 1.5rem;
      border-radius: 10px;
      gap: 1rem;
      img {
        width: 30px;
      }
      figcaption {
        div {
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
    }
  }

  ${'' /* Country Picker Modal */}
  .heritage-selection-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${functions.rgba(colors.black, 0.5)};
    z-index: ${properties.highestZIndex};
    .modal-wrapper {
      ${snippets.absoluteCenter}
      width: ${properties.maxFieldWidth};
      max-width: 80%;
      background-color: ${colors.white};
      border-radius: 10px;
      padding: 2rem;
    }
  }
`;

export default Styles;