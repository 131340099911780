import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UpdateFamilyData } from "../../../requests/families/types";
import { consolePaths } from "../../../routes/_console/paths";
import { RadioButtonField } from "../../../components/forms";
import { Crest } from "../../../requests/crests/types";
import useFamilies from "../../../requests/families";
import useCrests from "../../../requests/crests";
import { FamilySetupFormProps } from "./types";



function CrestForm({
  FormNavigation,
  family
}: FamilySetupFormProps) {
  const navigate = useNavigate();
  const { listCrests } = useCrests();
  const { updateMyFamily } = useFamilies();
  const [crests, setCrests] = useState<Crest[]>([]);
  const [selectedCrest, setSelectedCrest] = useState<string>(
    family?.crestId
      ? family.crestId.toString()
      : ""
  );


  /**********************/
  /** Save Chosen Crest */
  const saveCrest = () => {
    const data: UpdateFamilyData = {
      crestId: parseInt(selectedCrest.toString())
    }
    updateMyFamily(data)
      .then(() => {
        navigate(consolePaths.FAMILY);
      }).catch(() => null);
  }

  /***********************/
  /** Get List of Crests */
  const getCrests = useCallback(() => {
    const params = { type: "family" };
    listCrests(params)
      .then((crests) => {
        setCrests(crests);
      }).catch(() => null);
  }, [])


  useEffect(() => {
    getCrests();
  }, [getCrests])



  return (
    <div className="crest-form">
      <div className="field-info mb-1">
        Your crest is a unique badge of honor that symbolizes{" "}
        your Family's heritage and pride.
      </div>
      <div className="crest-fields">
        {crests.map((crest) =>
          <div className="field-column" key={crest.id}>
            <RadioButtonField
              className="crest"
              name="crest"
              value={crest.id.toString()}
              selectedValue={selectedCrest}
              onChange={(e) => setSelectedCrest(e.target.value)}
            >
              <figure className="flex align-center">
                <img src={crest.url} alt="" />
                <figcaption>
                  <div className="title">
                    {crest.name}
                  </div>
                  <div className="description">
                    {crest.description}
                  </div>
                </figcaption>
              </figure>
            </RadioButtonField>
          </div>
        )}
      </div>
      <FormNavigation
        next={(
          <button
            disabled={!selectedCrest}
            className="button auto-width"
            onClick={saveCrest}
          >
            Use this Crest
          </button>
        )}
      />
    </div>
  );
}

export default CrestForm;