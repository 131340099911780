import { Link } from "react-router-dom";

import seats from "../../../assets/vector-images/panel/seats-red.svg";
import { InfoPanel } from "../../../components/layout";
import { rootPaths } from "../../../routes/paths";



function NoSeats() {
  return (
    <InfoPanel
      icon={seats}
      title="No Available Seats in Family"
      description={(
        <div>
          This family no longer has available slots for this invitation.{" "}
          Kindly ask the Parent / Family Admin to upgrade their account{" "}
          and try again.
        </div>
      )}
    >
      <Link
        to={rootPaths.HOMEPAGE}
        className="button white-black"
      >
        Back Home
      </Link>
    </InfoPanel>
  );
}

export default NoSeats;