import styled from "styled-components";

import { colors, functions, resolutions } from "../../../styles";
import checkmark_white from
  "../../../assets/vector-images/generic/check-white.svg";
import checkmark from
  "../../../assets/vector-images/generic/check-green.svg";



const Styles = styled.div`
  flex: 1;
  header {
    column-gap: 2rem;
    row-gap: 1rem;
    .heading {
      margin-bottom: 0;
    }
    .select-field {
      width: fit-content;
      select {
      }
    }
    .toggle-field {
      label {
        border: 1px solid ${colors.primary};
        border-radius: 50px;
        overflow: hidden;
        .flex {
          color: ${colors.grey3};
          position: relative;
          width: 200px;
          .option {
            flex-basis: 50%;
            padding: 7px 17px;
            font-weight: 700;
            color: ${colors.primary};
            transition: color 300ms ease-in-out;
            text-align: center;
            &.active {
              color: ${colors.white};
            }
          }
        }
        .slider {
          position: absolute;
          top: 0;
          right: 0;
          background-color: ${colors.primary};
          width: 50%;
          height: 100%;
          border-radius: 50px;
          transition: transform 300ms ease-in-out;
          &.active {
            transform: translateX(-100%);
          }
        }
      }
      input {
        &:focus {
          & ~ label {
            border-color: ${colors.primary} !important;
          }
        }
      }
    }
  }
  .content-wrapper {
    border: 7px solid ${functions.rgba(colors.primary, 0.1)};
    border-radius: 20px;
    background-color: ${colors.white};
    padding: 2rem;
    width: auto;
    @media(max-width: ${resolutions.tabletLarge}) {
      border: none;
      padding: 1rem;
    }
    @media(max-width: ${resolutions.tablet}) {
      padding: 0;
    }
    .plans {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
      justify-content: center;
      .plan {
        border-radius: 20px;
        background-color: ${colors.white};
        padding: 2rem 2.5rem;
        padding-top: 4rem;
        @media(max-width: ${resolutions.tablet}) {
          padding: 1rem;
        }
        ${'' /* Plan Name and Price */}
        .head {
          .name {
            font-size: 20px;
            font-weight: 600;
          }
          .price {
            margin-top: 0.5rem;
            gap: 4px;
            font-size: 20px;
            align-items: flex-end;
            font-weight: 700;
            .interval {
              font-size: 1rem;
            }
          }
        }
        ${'' /* Plan Description */}
        .content {
          margin: 2rem 0;
          @media(max-width: ${resolutions.tablet}) {
            margin: 1rem 0;
            margin-top: 0.5rem;
          }
          .title {
            margin-bottom: 1rem;
            font-size: 1.1rem;
          }
          .features {
            gap: 12px;
            .feature {
              position: relative;
              padding-left: 20px;
              font-size: 1rem;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(${checkmark});
                height: 14px;
                width: 14px;
              }
            }
          }
        }
        .button.choose-plan {
          margin-top: auto;
          border-radius: 12px;
        }
        &:last-child {
          background-color: ${colors.primary};
          color: ${colors.white};
          .content {
            .feature {
              &::before {
                background-image: url(${checkmark_white});
              }
            }
          }
          .button.choose-plan {
            color: ${colors.primary};
            background-color: ${functions.colorLuminance(colors.white, -0.1)};
            &::before {
              background-color: ${colors.white};
            }
          }
        }
      }
    }

    ${'' /* Free Trial Text */}
    .free-trial {
      margin-top: 2rem;
      text-align: center;
    }
  }
`;

export default Styles;