import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import {
  AcceptFamilyInviteResponse,
  CreateFamilyInviteData,
  FamilyInvite
} from "./types";



/*  ############################### */
/** ## FAMILY INVITE API REQUESTS # */
/*  ############################### */
const useFamilyInvites = () => {
  /*************************/
  /** Create Family Invite */
  const createFamilyInvite = (data: CreateFamilyInviteData) => {
    return new Promise<FamilyInvite>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/family-invites/`,
        method: "post",
        data
      }).then((res) => {
        const familyInvite = res.data as FamilyInvite;
        resolve(familyInvite);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /*****************************/
  /** List Sent Family Invites */
  const listSentFamilyInvites = () => {
    return new Promise<PaginatedResponse<FamilyInvite>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/family-invites/sent/`
      }).then((res) => {
        const familyInvites = res.data as PaginatedResponse<FamilyInvite>;
        resolve(familyInvites);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /**********************/
  /** Get Family Invite */
  const getFamilyInvite = (familyInviteId: string) => {
    return new Promise<FamilyInvite>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/family-invites/${familyInviteId}`
      }).then((res) => {
        const familyInvite = res.data as FamilyInvite;
        resolve(familyInvite);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  /*************************/
  /** Accept Family Invite */
  const acceptFamilyInvite = (familyInviteId: string) => {
    return new Promise<AcceptFamilyInviteResponse>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/family-invites/${familyInviteId}/accept`
      }).then((res) => {
        const response = res.data as AcceptFamilyInviteResponse;
        resolve(response);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }
  /*************************/
  /** Accept Family Invite */
  const deleteFamilyInvite = (familyInviteId: string) => {
    return new Promise<void>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/family-invites/${familyInviteId}/`,
        method: "delete"
      }).then(() => {
        resolve();
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }



  return {
    createFamilyInvite,
    listSentFamilyInvites,
    getFamilyInvite,
    acceptFamilyInvite,
    deleteFamilyInvite
  }
}

export default useFamilyInvites;