export const randNum = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const commaDelimited = (number: number | string): string => (
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);

export const compareNumbers = (key: string) =>
  (a: any, b: any) => a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;

export const genRanHex = (size: number) =>
  [...Array(size)].map(() =>
    Math.floor(Math.random() * 16).toString(16)
  ).join('');

export const randomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

/*******************************************************/
/** Returns a price as a minor currency unit (Integer) */
export const minorPrice = (price: any): number => {
  const decimal = parseFloat(price.toString()).toFixed(2);
  const intStr = decimal.replace(".", "").replace(",", "");
  const intValue = parseInt(intStr, 10);

  return intValue;
}

/*********************************/
/** Returns a price as a decimal */
export const decimalPrice = (input: any): string => {
  // Convert input to string and ensure it's at least 3 characters long by padding with zeros if necessary
  let intStr = typeof input === 'number' ? input.toString() : input;
  intStr = intStr.padStart(3, '0');
  // Format the string to include a decimal point before the last two digits
  const decimalValue = `${intStr.slice(0, -2)}.${intStr.slice(-2)}`;

  return commaDelimited(decimalValue);
}