import {
  Dispatch, SetStateAction, useRef, MouseEvent, useEffect
} from "react";

import { CountrySelect } from "../../../components/forms";
import { useInput } from "../../../utils/forms";



type Props = {
  pickerOpen: boolean;
  setPickerOpen: Dispatch<SetStateAction<boolean>>
  addHeritage: (e: MouseEvent, heritage: string) => void;
}

function HeritagePicker({
  pickerOpen,
  setPickerOpen,
  addHeritage
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    value: country,
    setValue: setCountry,
    stringChange: countryCh
  } = useInput({
    initialValue: ""
  });
  const {
    value: search,
    setValue: setSearch,
    stringChange: searchCh
  } = useInput({
    initialValue: ""
  });


  /***********************************/
  /** Close the Country Picker Modal */
  const closeHeritagePicker = () => {
    setCountry("");
    setSearch("");
    setPickerOpen(false);
  }


  // Effect for closing modal on Escape key press
  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeHeritagePicker();
      }
    }
    document.addEventListener(
      "keydown",
      handleEscapePress
    );

    return () => (
      document.removeEventListener(
        "keydown",
        handleEscapePress
      )
    );
  }, [closeHeritagePicker]);

  // Effect for closing modal on outside click
  useEffect(() => {
    const handleOutsideClick: EventListener = (event) => {
      const mouseEvent = event as any;
      const target = mouseEvent.target as Node;
      if (modalRef.current && !modalRef.current.contains(target)) {
        closeHeritagePicker();
      }
    }
    document.addEventListener(
      "mousedown",
      handleOutsideClick
    );

    return () => (
      document.removeEventListener(
        "mousedown",
        handleOutsideClick
      )
    );
  }, [closeHeritagePicker]);



  return (
    pickerOpen ? (
      <div className="heritage-selection-modal">
        <div className="modal-wrapper" ref={modalRef}>
          <CountrySelect
            country={country}
            countryCh={countryCh}
            search={search}
            searchCh={searchCh}
          />
          <button
            className="button mlr-auto auto-width submit"
            disabled={!country}
            onClick={(e) => {
              addHeritage(e, country)
              closeHeritagePicker();
            }}
          >
            Add Heritage
          </button>
        </div>
      </div>
    ) : null
  );
}

export default HeritagePicker;