import { Link, useOutletContext } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";

import info_icon from "../../../assets/vector-images/dashboard/calender-info.svg";
import sub_icon from "../../../assets/vector-images/dashboard/subscription.svg";
import { Subscription } from "../../../requests/subscriptions/types";
import account from "../../../assets/vector-images/dashboard/account.svg";
import useSubscriptions from "../../../requests/subscriptions";
import { consolePaths } from "../../../routes/_console/paths";
import { getPlanInterval } from "../../../utils/payments";
import { isEmail } from "../../../utils/strings";
import { ConsoleContext } from "..";
import { CardLink } from "./types";
import Styles from "./styles";



function Dashboard() {
  const { user } = useOutletContext<ConsoleContext>();
  const { getMySubscription } = useSubscriptions();
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const cardLinks: CardLink[] = useMemo(() => [
    {
      title: "Account Profile",
      detail: isEmail(user.username)
        ? (
          user.emailVerified
            ? (
              "Email account verified"
            ) : (
              "Email account not verified"
            )
        ) : (
          "Child Account"
        ),
      image: account,
      path: {
        destination: consolePaths.ACCOUNT,
        text: "View Account Details"
      }
    },
    {
      title: "Subscription",
      detail: (
        (subscription && subscription.planPrice) ? (
          `Currently on ${subscription.planPrice.plan.name} Plan${" - "}
          ${getPlanInterval(subscription.planPrice.interval)}`
        ) : "Currently on the Free Plan"
      ),
      image: sub_icon,
      path: {
        destination: consolePaths.SUBSCRIPTION,
        text: "View Subscription"
      }
    },
    {
      title: "Signed Up",
      detail: user.createdAt ? (
        moment(user.createdAt).format("Do MMMM YYYY, hh:mma")
      ) : "-",
      image: info_icon
    }
  ], [user, subscription])


  useEffect(() => {
    getMySubscription()
      .then((subscription) => {
        if (subscription)
          setSubscription(subscription);
      }).catch(() => null)
  }, [])



  return (
    <>
      <h1 className="page-title">
        Dashboard
      </h1>
      <Styles className="page-wrapper">
        <ul className="card-links flex column">
          {cardLinks.map((card) =>
            <li
              className="card-link flex justify-between align-center"
              key={card.title}
            >
              <figure className="flex">
                <img src={card.image} alt="" />
                <figcaption className="flex column">
                  <h4>
                    {card.title}
                  </h4>
                  <div className="grey-text">
                    {card.detail}
                  </div>
                </figcaption>
              </figure>
              {(card.path) ? (
                <Link
                  to={card.path.destination}
                  className="link underline black"
                >
                  {card.path.text}
                </Link>
              ) : null}
            </li>
          )}
        </ul>
      </Styles>
    </>
  );
}

export default Dashboard;