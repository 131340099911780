import styled from "styled-components";



const Styles = styled.figcaption`
  .image {
    height: 40px;
    max-width: 40px;
  }
  .details {
    gap: 2px;
  }
`;

export default Styles;