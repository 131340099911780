function PrivacyTerms() {
  return (
    <footer className="privacy-terms">
      By continuing, you agree to the{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://schoolofnewafrica.com/terms-and-conditions/"
        className="link inherit underline"
      >
        Terms and Conditions
      </a>
      {" "}and{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://schoolofnewafrica.com/privacy/"
        className="link inherit underline"
      >
        Privacy Policy
      </a>
      {" "} for SONA
    </footer>
  );
}

export default PrivacyTerms;