import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";



// Context (Props) passed down to Child Pages (<Outlet/>)
export type SecurityContext = AuthRouteContext;

function Security() {
  const { user } = useOutletContext<SecurityContext>();



  return (
    <div className="root-content bg-action-pose faded">
      <Outlet context={{ user }} />
    </div>
  );
}

export default Security;