import styled from "styled-components";



const Styles = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  header {
    text-align: center;
  }
`;

export default Styles;