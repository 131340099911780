import { Link } from "react-router-dom";

import succes from "../../../assets/vector-images/panel/success.svg";
import ananse_happy from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import ananse_sad from
  "../../../assets/vector-images/ananse/bust_sad_stafff_no glow.svg";
import error from "../../../assets/vector-images/panel/error.svg";
import { missionPaths } from "../../../routes/missions/paths";
import { useQueryParams } from "../../../utils/components";
import Styles from "./styles";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";



function OnboardingMission() {
  const { queryParams } = useQueryParams();
  const correct = queryParams.get("correct") || "0";
  const passed = parseInt(correct.toString()) >= 2;



  return (
    <Styles className="root-content">
      <div className="page-wrapper">
        <header className="flex column center">
          <img src={passed ? succes : error} alt="" />
          <h1 className="title">
            {parseInt(correct.toString())}/3{" "
            }Answers Correct!
          </h1>
        </header>
        {passed ? (
          <section>
            <figure className="panel">
              <img src={ananse_happy} alt="" />
              <figcaption className="message-box passed">
                Congratulations!
                <br />
                You're ready to go!
              </figcaption>
            </figure>
            <Link
              to={nonAuthPaths.SIGN_UP_WAITING_LIST}
              className="button mt-2"
            >
              Awesome!
            </Link>
          </section>
        ) : (
          <section>
            <figure className="panel">
              <img src={ananse_sad} alt="" />
              <figcaption className="message-box failed">
                You failed this mission.
                <br />
                Try that again!
              </figcaption>
            </figure>
            <Link
              to={missionPaths.ONBOARDING_QUIZ}
              className="button white-black mt-2"
            >
              Retry Mission
            </Link>
          </section>
        )}
      </div>
    </Styles>
  );
}

export default OnboardingMission;