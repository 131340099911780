import styled from "styled-components";

import check_fill from "../../../assets/vector-images/generic/check-fill.svg";
import { colors, properties } from "../../../styles";



export const rootToggleStyles = `
  position: relative;
  font-size: 0.8rem;
  flex-shrink: 0;
  max-width: ${properties.maxFieldWidth};
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    ${'' /* For keyboard focusing */}
    &:focus {
      & ~ label {
        border-color: ${colors.secondary} !important;
      }
    }
  }
  label {
    user-select: none;
    font-size: inherit;
    margin: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: ${properties.transition};
    ${'' /* For keyboard focusing */}
    border: 1px solid transparent;
  }
`;


const Styles = styled.div`
  ${rootToggleStyles}
  input {
    ${'' /* For keyboard focusing */}
    &:focus {
      & ~ label {
        border-color: ${colors.primary} !important;
      }
    }
  }
  label {
    padding: 1rem;
    border: 1px solid ${colors.grey10};
    border-radius: 7px;
    background-color: ${colors.white};
    font-size: 1rem;
    position: relative;
    padding-left: 2.75rem;
    font-weight: 600;
    &::before {
      content: "";
      position: absolute;
      left: 1.5rem;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 50%;
      border: 1px solid ${colors.grey10};
    }
  }
  input:checked ~ label {
    border-color: ${colors.primary};
    background-color: #e5f5ec;
    &::before{
      background-image: url(${check_fill});
      background-size: 110%;
      background-position: center;
      border-color: transparent;
    }
  }
`;

export default Styles;