import axios from "axios";

import { ErrorResponse } from "../../types";
import { Avatar } from "./types";



/*  ######################## */
/** ## AVATAR API REQUESTS # */
/*  ######################## */
const useAvatars = () => {
  /*********************/
  /** List All Avatars */
  const listAvatars = () => {
    return new Promise<Avatar[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/avatars/`,
      }).then((res) => {
        resolve(res.data as Avatar[]);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listAvatars
  }
}

export default useAvatars;