import { useLocation } from "react-router-dom";
import { useRef, useState } from "react";

import { CreateFamilyInviteData } from "../../../requests/familyInvites/types";
import { FormField, FormValues } from "../../../components/forms/Form/types";
import { familyRelationships, familyRoles } from "../../../constants/auth";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import useFamilyInvites from "../../../requests/familyInvites";
import { usePageControls } from "../../../utils/components";
import { familyPaths } from "../../../routes/family/paths";
import { ClipboardCopy } from "../../../components/layout";
import useAppActions from "../../../redux/actions/app";
import { Form } from "../../../components/forms";



function CreateInvite() {
  const { pathname } = useLocation();
  const { goTo } = usePageControls();
  const { setAppLoading } = useAppActions();
  const { createFamilyInvite } = useFamilyInvites();
  const { setAppModal } = useAppModal();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});


  /****************/
  /** Submit Data */
  const createInvite = () => {
    setAppLoading(true);
    createFamilyInvite(formValues as CreateFamilyInviteData)
      .then((invite) => {
        const { inviteLink } = invite;
        setAppModal({
          title: "Invite Link Created!",
          children: (
            <div className="modal-content">
              <div className="msg">
                You can now share this link to have them join your Family:
              </div>
              <div className="flex justify-between mt-2">
                <div className="invite-link">
                  {inviteLink}
                </div>
                <ClipboardCopy text={inviteLink} />
              </div>
            </div>
          ),
          cancelCallback: () => {
            goTo(familyPaths.viewInvites(pathname));
          }
        });
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "select",
      name: "role",
      label: "Account Type",
      options: familyRoles,
      groupClass: "field-row 1"
    },
    {
      type: "select",
      name: "relationship",
      label: "Family Relationship",
      options: familyRelationships,
      groupClass: "field-row 1"
    },
    {
      type: "email",
      name: "inviteeUsername",
      label: "Create an invite for a specific person (Optional)",
      placeholder: "user@example.com (Optional)",
      required: false,
      autoComplete: "off",
      groupClass: "field-group"
    }
  ]



  return (
    <div className="page-wrapper">
      <header>
        <div className="title">
          Invite to Family
        </div>
        <div className="description">
          Create a link that others can use to join your family
        </div>
      </header>
      <Form
        fields={formFields}
        values={formValues}
        setValues={setFormValues}
        onSubmit={createInvite}
        ref={formRef}
      >
        <button
          className="button auto-width submit"
          type="submit"
        >
          Create Invitation Link
        </button>
      </Form>
    </div>
  );
}

export default CreateInvite;