import {
  Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState
} from "react";

import { Form } from "../../../../components/forms";
import {
  FormField, FormValues
} from "../../../../components/forms/Form/types";
import {
  addressDocs, allAcceptedKycDocs,
  doubleFieldDocs, identityDocs,
  Kyc,
  RequiredDocument,
} from "../../../../requests/kyc/types";



type Props = {
  kyc?: Kyc;
  title: string;
  documentType: RequiredDocument;
  formValues: FormValues;
  setFormValues: Dispatch<SetStateAction<FormValues>>;
  onSubmit: () => void;
  children: ReactNode;
}

function UploadDocForm({
  kyc,
  title,
  formValues,
  documentType,
  setFormValues,
  onSubmit,
  children
}: Props) {
  const [fields, setFields] = useState<FormField[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const defaultFields: FormField[] = [
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group",
      reactNode: (
        <div className="grey-text mb-2 bold">
          {title}
        </div>
      )
    },
    {
      type: "select",
      name: "reference",
      label: "Document Type",
      options: (
        kyc ? (
          documentType === "address"
            ? [
              ...addressDocs
            ] : [
              ...identityDocs
            ]
        ) : []
      ),
      placeholder: "Choose the Document Type",
      groupClass: "field-group"
    },
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group mb-2",
      reactNode: (
        <div className="field-info">
          {formValues && formValues.reference ? (
            allAcceptedKycDocs.find(doc =>
              doc.value === formValues.reference
            )?.details
          ) : ""}
        </div>
      )
    }
  ]

  useEffect(() => {
    if (!formValues.reference) {
      setFields(defaultFields)
      return;
    }
    // Remove existing files when the reference is changed 
    setFormValues((prevState) => ({
      ...prevState,
      files: undefined
    }));
    if (doubleFieldDocs.includes(formValues.reference)) {
      setFields([
        ...defaultFields,
        {
          type: "file",
          name: "file1",
          label: "Upload Document",
          placeholder: "Upload the Front of the ID",
          groupClass: "field-row",
        },
        {
          type: "file",
          name: "file2",
          label: "Upload Document",
          placeholder: "Upload the Back of the ID",
          groupClass: "field-row",
        }
      ]);
    } else {
      setFields([
        ...defaultFields,
        {
          type: "file",
          name: "files",
          label: "Upload Document",
          placeholder: "Tap to add Document(s)",
          groupClass: "field-row",
          multiple: true
        }
      ]);
    }
  }, [kyc, formValues.reference])



  return (
    <Form
      fields={fields}
      values={formValues}
      setValues={setFormValues}
      onSubmit={onSubmit}
      ref={formRef}
    >

      {children}
    </Form>
  );
}

export default UploadDocForm;