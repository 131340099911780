import styled from "styled-components";

import { properties, colors, snippets } from "../../../styles";



const Styles = styled.div`
  border-radius: ${properties.borderRadius};
  background-color: ${colors.white};
  padding: 25px 27px;
  max-width: 120rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .table-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    ${snippets.scrollBar}
    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      min-width: 30rem;
      td, th {
        vertical-align: middle;
        font-size: 12px;
        padding: 20px 0;
        border-bottom: 1px solid ${colors.grey8};
        text-align: center;
        &:nth-of-type(1) {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        img {
          max-height: 5rem;
        }
      }
      th {
        padding-top: 0;
        user-select: none;
        position: sticky;
        top: 0;
        cursor: pointer;
      }
      td {
        &:nth-of-type(1) {
          font-weight: 400;
        }
      }
      tr {
        &.clickable {
          cursor: pointer;
        }
        &:last-child {
          td {
            border: none;
            padding-bottom: 0;
          }
        }
      }
    }
    .info-panel {
      padding: 2rem 0;
      flex: 1;
    }
  }
  
  ${''/* PAGINATION */}
  .pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 22px;
    .nav {
      color: ${colors.primary};
      font-size: 11px;
      &:disabled {
        color: ${colors.grey11};
      }
    }
    .numbers {
      display: flex;
      align-items: center;
      gap: 12px;
      .num {
        border: 1px solid ${colors.grey11};
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 10px;
        color: ${colors.grey11};
        font-weight: 600;
        &:first-of-type {
          background-color: ${colors.primary};
          color: ${colors.white};
          border-color: ${colors.primary};
        }
      }
    }
  }
  

  ${''/* Cell Classes */}
  .table-title {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      height: 30px;
    }
    div {
      font-weight: 600;
    }
  }
  .actions {
    gap: 1rem;
    img {
      height: 14px;
    }
  }
  .grey {
    color: ${colors.grey6};
  }
`;

export default Styles;