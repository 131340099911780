import { useEffect, useRef } from "react";

import { FormField } from "../../../components/forms/Form/types";
import { Form } from "../../../components/forms";
import { FormPageProps } from "./types";



function Nickname({
  FormNavigation,
  values: formValues,
  setValues: setFormValues,
  nextStep,
  user,
  emailUsername
}: FormPageProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const usersPronoun = emailUsername ? "parent's" : "learner's";

  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group mb-1",
      reactNode: (
        <div className="field-info bold">
          This would be the {usersPronoun} display name on in the SONA community
        </div>
      )
    },
    {
      type: "text",
      name: "nickName",
      label: "Nickname",
      placeholder: "Nickname",
      groupClass: "field-group"
    }
  ]

  useEffect(() => {
    if (!user) return;
    const { nickName } = user;
    setFormValues((prevState) => ({
      ...prevState,
      nickName,
    }));
  }, [user])



  return (
    <Form
      values={formValues}
      setValues={setFormValues}
      onSubmit={nextStep}
      ref={formRef}
      fields={fields}
    >
      <FormNavigation />
    </Form>
  );
}

export default Nickname;