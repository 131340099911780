import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import anase_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import { useTabVisibilityChange } from "../../../utils/components";
import useSubscriptions from "../../../requests/subscriptions";
import { InfoPanel, Loader } from "../../../components/layout";
import { User } from "../../../requests/users/types";
import { rootPaths } from "../../../routes/paths";
import useUsers from "../../../requests/users";



function SaveCard() {
  const navigate = useNavigate();
  const { getUserProfile } = useUsers();
  const { getMySubscription, saveCard } = useSubscriptions();
  const [user, setUser] = useState<User>();
  const [addPayment, setAddPayment] = useState<boolean>(false);


  /*********************/
  /** Get User Profile */
  const fetchUserProfile = useCallback(() => {
    getUserProfile()
      .then((resData) => { setUser(resData) })
      .catch(() => null);
  }, []);

  /*****************************************/
  /** Get Payment Page URL for saving card */
  const addPaymentMethod = (e: MouseEvent) => {
    e.preventDefault();
    setAddPayment(false);
    saveCard()
      .then((resData) => {
        window.location.href = resData.paymentUrl;
      })
      .catch(() => null)
      .finally(() => {
        setAddPayment(true);
      });
  }


  useEffect(() => {
    if (!user) return;
    getMySubscription()
      .then((subscription) => {
        // Non-null planPrice means user has an active subscription
        if (subscription && subscription.planPrice) {
          if (
            (subscription.managerId === user.id) &&
            !user.cardOnFile
          ) {
            setAddPayment(true);
          } else {
            navigate(profileSetupPaths.NAME_ENTRY);
          }
        } else {
          navigate(rootPaths.SUBSCRIPTIONS);
        }
      }).catch(() => null)
  }, [user])

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  // Update User when tab becomes visible again
  useTabVisibilityChange(fetchUserProfile);



  return (
    !addPayment ? (
      <Loader />
    ) : (
      <div className="page-wrapper">
        <InfoPanel
          icon={anase_smile}
          title="Add a Payment Method"
          description={(
            `To provide you with uninterrupted service, SONA needs${" "
            }to save your card details for automatic subscription renewals.`
          )}
        >
          <button
            className="button"
            onClick={addPaymentMethod}
          >
            Add Payment Method
          </button>
        </InfoPanel>
      </div>
    )
  );
}

export default SaveCard;