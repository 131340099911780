import { Link, useOutletContext } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";

import { ActiveSubscription } from "../../../requests/subscriptions/types";
import { SelectFieldOption } from "../../../components/forms/SelectField";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import filter from "../../../assets/vector-images/generic/filter.svg";
import { LabelTooltip, SelectField } from "../../../components/forms";
import useSubscriptions from "../../../requests/subscriptions";
import { consolePaths } from "../../../routes/_console/paths";
import { useQueryParams } from "../../../utils/components";
import { currencySymbol } from "../../../utils/strings";
import useAddresses from "../../../requests/addresses";
import { decimalPrice } from "../../../utils/numbers";
import useSubscriptionUtils, { hasActiveSubscription }
  from "../../../requests/subscriptions/utils";
import { Loader } from "../../../components/layout";
import { useInput } from "../../../utils/forms";
import usePlans from "../../../requests/plans";
import { SubscriptionsContext } from "..";
import {
  PlanPrice,
  Plan
} from "../../../requests/plans/types";
import Styles from "./styles";



export const IntervalOptions: SelectFieldOption[] = [
  {
    value: "month",
    label: "Monthly"
  },
  {
    value: "year",
    label: "Annually"
  }
]

function SubscriptionList() {
  const { user } = useOutletContext<SubscriptionsContext>()
  const { getUsersAddress } = useAddresses();
  const { queryParams, updateFilters } = useQueryParams();
  const interval = queryParams.get("interval") || "year";
  const { listPlans } = usePlans();
  const { getMySubscription } = useSubscriptions();
  const { subscribeToPlan } = useSubscriptionUtils();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [country, setCountry] = useState<string>("GB");
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | null
  >(null);
  const {
    value: intervalValue,
    setValue: setIntervalValue
  } = useInput({
    initialValue: interval || "year",
    selectValues: IntervalOptions.map(option => option.value)
  });


  const updateInterval = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setIntervalValue(value);
    updateFilters("interval", value);
  }

  /************************************/
  /** OnClick event for choosing plan */
  const selectPlan = (
    e: React.MouseEvent,
    planPrice: PlanPrice,
    plan: Plan
  ) => {
    e.preventDefault();
    subscribeToPlan({
      plan,
      planPrice,
      activeSubscription
    });
  }

  /******************/
  /** Get Plan List */
  const getPlanList = (cty: string = country) => {
    return new Promise<void>((resolve) => {
      const params: Record<string, string> = {
        interval,
        country: cty
      }
      listPlans(params)
        .then((resData: Plan[]) => {
          setPlans(resData);
        }).catch(() => null)

      resolve();
    });
  }


  useEffect(() => {
    if (firstRender) return;
    getPlanList();
  }, [interval])

  useEffect(() => {
    getUsersAddress()
      .then((address) => {
        getMySubscription()
          .then((subscription) => {
            let country = "GB";
            const activeSubscription = hasActiveSubscription(subscription);
            if (activeSubscription) {
              // Use the region of the current subscription
              country = activeSubscription.planPrice.plan.region;
              const newInterval = activeSubscription.planPrice.interval;
              // setIntervalValue(newInterval);
              // updateFilters("interval", newInterval);
              setActiveSubscription(activeSubscription);
            } else {
              country = (
                (address && address.country)
                  ? address.country
                  : "GB"
              );
            }
            setCountry(country);
            getPlanList(country)
              .then(() => {
                setFirstRender(false);
              }).catch(() => null);
          }).catch(() => null);
      })
      .catch(() => null);
  }, [])



  return (
    <Styles className="page-wrapper">
      {(plans.length > 0) ? (
        <>
          {/* Heading */}
          <header className="flex justify-between align-center wrap">
            <div className="heading">
              <h3 className="title">
                Select Subscription Plan
              </h3>
              <div className="description">
                Choose a subscription to continue with SONA
              </div>
            </div>
            <div className="field-column">
              <LabelTooltip htmlFor="interval">
                Billing Cycle
              </LabelTooltip>
              <SelectField
                name="interval"
                icon={filter}
                options={IntervalOptions}
                value={intervalValue}
                onChange={updateInterval}
                placeholder="Billing Cycle"
              />
            </div>
          </header>

          {/* Available Plans */}
          <section className="content-wrapper">
            <div className="plans">
              {plans.map((plan) =>
                <div className="plan flex column" key={plan.id}>
                  <div className="head">
                    {/* Plan Name and Price */}
                    <div className="name">
                      {plan.name}
                    </div>
                    <div className="price flex">
                      {currencySymbol(plan.planPrices[0].currency)}
                      {decimalPrice(plan.planPrices[0].amount)}
                      <div className="interval">
                        {`/ ${plan.planPrices[0].interval}`}
                      </div>
                    </div>
                  </div>
                  {/* Plan Description */}
                  <div className="content">
                    <div className="title">
                      {plan.featureTitle}
                    </div>
                    <ul className="features flex column">
                      {plan.planFeatures.map(feature =>
                        <li className="feature" key={feature.id}>
                          {feature.description}
                        </li>
                      )}
                    </ul>
                  </div>

                  {/* Plan Controls */}
                  {(
                    activeSubscription &&
                    (activeSubscription.planPriceId === plan.planPrices[0].id)
                  ) ? (
                    // Subscribed
                    <Link
                      className="button submit white-black choose-plan"
                      to={user.completedOnboarding
                        ? consolePaths.SUBSCRIPTION
                        : profileSetupPaths.NAME_ENTRY
                      }
                    >
                      Subscribed
                    </Link>
                  ) : (
                    // Switch to Plan
                    <button
                      className="button submit choose-plan"
                      onClick={e => selectPlan(
                        e,
                        plan.planPrices[0],
                        plan
                      )}
                    >
                      Choose Plan
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="free-trial">
              7-day free trial.{" "}
              You can cancel or upgrade your plan anytime!
            </div>
          </section>
        </>
      ) : (
        <Loader message="Retrieving Subscriptions ..." />
      )}
    </Styles>
  );
}

export default SubscriptionList;