import { useEffect, useState } from "react";

import binoculars_icon from "../../../assets/vector-images/panel/binoculars.svg";
import { TableColumn, TableRow, TableProps } from "./types";
import { useTableUtils } from "./utils";
import InfoPanel from "../InfoPanel";
import Styles from "./styles";



function Table(props: TableProps) {
  const {
    columns,
    rowId,
    data: initialData,
    pages = 0,
    onRowClick,
    draggable = false,
    className,
    noData,
    emptyMessage
  } = props;
  const [tableData, setTableData] = useState(initialData);
  const {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleRowClick
  } = useTableUtils(
    props,
    tableData,
    setTableData
  );


  useEffect(() => {
    setTableData(initialData);
  }, [initialData])



  return (
    <Styles className={`table-root ${className ? className : ""}`}>
      <div className="table-wrapper">
        <table>
          {/* HEADING */}
          <thead>
            <tr>
              {columns.map((column: TableColumn, index) =>
                <th
                  key={index}
                  className={column.className || ""}
                >
                  {column.title}
                </th>
              )}
            </tr>
          </thead>

          {/* BODY */}
          <tbody>
            {tableData.map((row: TableRow, index: number) =>
              <tr
                key={rowId ? row[rowId] : row.id}
                className={`${onRowClick ? "clickable" : ""}`}
                draggable={draggable}
                onDragStart={handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={handleDrop(index)}
              >
                {columns.map((column: TableColumn) =>
                  <td
                    key={`${row.id}${column.key}`}
                    onClick={handleRowClick(rowId
                      ? row[rowId] : row.id, column.key)}
                    className={column.className || ""}
                  >
                    {column.content
                      ? column.content(row)
                      : row[column.key]
                    }
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
        {tableData.length === 0 ? (
          <InfoPanel
            icon={noData?.icon ?? binoculars_icon}
            title={noData?.title ?? "No Data"}
          >
            <div className="description">
              {noData && noData.description ? (
                noData.description
              ) : (
                emptyMessage ? (
                  emptyMessage
                ) : (
                  <>
                    There were no results for this search term.<br />
                    Try another term and we'll show results if there are any.
                  </>
                )
              )}
            </div>
          </InfoPanel>
        ) : null}
      </div>

      {/* PAGINATION */}
      {(pages > 0)
        ? <ul className="pagination">
          <button className="nav" disabled>
            Back
          </button>
          <div className="numbers">
            {[...Array(pages)].map((_, index) =>
              <button className="num">
                {index + 1}
              </button>
            )}
          </div>
          <button className="nav">
            Next
          </button>
        </ul>
        : null
      }
    </Styles>
  );
}

export default Table;