import { MouseEvent } from "react";

import { FamilyInvite } from "../../../requests/familyInvites/types";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import useFamilyInvites from "../../../requests/familyInvites";
import useAppActions from "../../../redux/actions/app";



const useViewInviteUtils = () => {
  const { deleteFamilyInvite } = useFamilyInvites();
  const { setAppLoading } = useAppActions();
  const { setAppModal, closeModal } = useAppModal();


  /****************************/
  /** Delete Family Invite */
  const deleteInvite = (e: MouseEvent, invite: FamilyInvite) => {
    e.preventDefault();
    closeModal();
    setAppLoading(`Deleting Invite Link ...`);
    deleteFamilyInvite(invite.id)
      .then(() => {
        setAppModal({
          title: "Invite Link Deleted",
          children: (
            `This Invite Link has successfully been deleted!`
          ),
          cancelCallback: () => {
            window.location.reload()
          }
        });
      })
      .catch(() => null)
      .finally(() => {
        setAppLoading(false);
      });
  }


  /*********************************************/
  /** Confirm before deleting Family Invite */
  const deleteInviteConfirmation = (e: MouseEvent, invite: FamilyInvite) => {
    e.preventDefault();
    setAppModal({
      title: "Delete Invite Link",
      children: (
        <div className="modal-content">
          If you delete this Link, others would not be able{" "
          }to join your Family with it.
          <br /><br />
          <div className="bold">
            Are you sure you want to delete this Invite Link?
          </div>
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={() => {
              closeModal();
            }}
          >
            No, Cancel
          </button>
          <button
            className="button error"
            onClick={(e) => deleteInvite(e, invite)}
          >
            Yes, Delete Link
          </button>
        </div>
      )
    });
  }



  return {
    deleteInviteConfirmation
  }
}

export default useViewInviteUtils;