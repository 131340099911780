import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../../styles";


const Styles = styled.div`
  header {
    gap: 1.5rem;
    img {
      width: 60px;
    }
    .title {
      font-size: 1rem;
    }
  }
  section {
    ${snippets.flexCenter}
    .panel {
      width: 100%;
      max-width: ${properties.maxFieldWidth};
      img {
        height: 200px;
      }
      .message-box {
        padding: 2rem;
        border-radius: 10px;
        text-align: center;
        width: 100%;
        &.passed {
          background-color: ${functions.rgba(colors.primary, 0.1)};;
        }
        &.failed {
          background-color: ${functions.rgba(colors.error, 0.1)};;
        }
      }
    }
  }
  
`;

export default Styles;