export const profileSetup = "/profile-setup";

export const kycRoot = `${profileSetup}/kyc`;



/************************/
/** Profile Setup Paths */
export const profileSetupPaths = {
  // Primary Onboarding
  COUNTRY_ENTRY: `${profileSetup}/country/`,
  SAVE_CARD: `${profileSetup}/save-card/`,
  NAME_ENTRY: `${profileSetup}/name/`,
  NICKNAME_ENTRY: `${profileSetup}/nickname/`,
  DOB_ENTRY: `${profileSetup}/dob/`,
  ADDRESS_ENTRY: `${profileSetup}/address/`,
  PROFILE_SUMMARY: `${profileSetup}/summary/`,

  // Secondary Onboarding
  AVATAR_SELECT: `${profileSetup}/avatar/`,
  HERITAGES: `${profileSetup}/heritages/`,
  ONBOARDING_MISSION: `${profileSetup}/onboarding-mission/`,
  MISSION_COMPLETED: `${profileSetup}/mission-completed/`,
  SECONDARY_CHECKLIST: `${profileSetup}/checklist/`,

  // KYC
  KYC_CHECK: `${kycRoot}/`,
  KYC_UPLOAD_DOCUMENT: `${kycRoot}/upload-document/`,
  KYC_UPLOAD_SUCCESS: `${kycRoot}/upload-success/`,
  KYC_UPLOAD_INSTRUCTIONS: `${kycRoot}/upload-instructions/`,
  KYC_VERIFICATION_PENDING: `${kycRoot}/verification-pending/`,
  KYC_VERIFICATION_SUCCESS: `${kycRoot}/verification-success/`,
  KYC_VERIFICATION_FAILED: `${kycRoot}/verification-failed/`,
  KYC_COMPLETE_FAIL: `${kycRoot}/complete-fail/`
}