import { ChangeEvent, PropsWithChildren } from "react";

import Styles from "./styles";


export interface Props extends PropsWithChildren, React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement
> {
  value: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedValue?: string;
}


function RadioButtonField(props: Props) {
  const {
    value,
    className,
    disabled,
    children,
    selectedValue,
    ...inputProps
  } = props;


  return (
    <Styles
      className={`radio-button-field ${className
        ? className
        : ""}`}
    >
      <input
        {...inputProps}
        type="radio"
        id={value}
        value={value}
        checked={selectedValue === value}
        disabled={disabled}
      />
      <label htmlFor={value}>
        {children}
      </label>
    </Styles>
  );
}

export default RadioButtonField;