import { useNavigate, useOutletContext } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";

import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../requests/users/types";
import DobField from "../../../components/forms/DobField";
import useUsers from "../../../requests/users";
import { ProfileSetupContext } from "..";
import Styles from "./styles";



function DobEntry() {
  const navigate = useNavigate();
  const { updateProfile } = useUsers();
  const { user } = useOutletContext<ProfileSetupContext>();
  const [dobValue, setDobValue] = useState<Date | string>("");
  const [validDob, setValidDob] = useState<boolean>(false);


  /*************/
  /** Save DOB */
  const saveData = (e: FormEvent) => {
    e.preventDefault();
    if (dobValue && validDob) {
      const profileData: UpdateProfileArgs = {
        dob: dobValue.toString().slice(0, 10)
      }
      updateProfile(profileData)
        .then(() => {
          navigate(profileSetupPaths.ADDRESS_ENTRY);
        }).catch(() => null);
    } else {
      return;
    }
  }


  useEffect(() => {
    if (!user) return;
    const { dob } = user;
    if (!dob) return;
    setDobValue(dob);
  }, [user])



  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          When were you born?
        </h3>
        <div className="subtitle">
          Kindly enter your Date of Birth (DOB) as it appears{" "
          }on your government-issued ID.
          <br />
          This is required for the SONA card program.
        </div>
      </header>

      {/* Date of Birth Form */}
      <section>
        <form onSubmit={saveData}>
          <DobField
            value={dobValue}
            setValue={setDobValue}
            validDob={validDob}
            setValidDob={setValidDob}
          />
          <button
            type="submit"
            className="button submit"
          >
            Save Details
          </button>
        </form>
      </section>
    </Styles>
  );
}

export default DobEntry;