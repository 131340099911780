import styled from "styled-components";

import { resolutions } from "../../../../styles";



const Styles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .page-wrapper {
    .content-wrapper {
      padding: 0;
      border: none;
      @media(max-width: ${resolutions.tabletLarge}) {
        padding: 1rem;
      }
      @media(max-width: ${resolutions.tablet}) {
        padding: 0;
      }
      .plans {
        justify-content: flex-start;
        .plan {
          padding: 1.5rem;
          &:last-child {
            @media(max-width: ${resolutions.tablet}) {
              margin: 1rem;
            }
          }
          .head {
            .name, .price {
              font-size: 15px;
            }
          }
          .content {
            margin-top: 1rem;
            .features {
              gap: 9px;
            }
          }
        }
      }
      .free-trial {
        display: none;
      }
    }
  }
`;

export default Styles;