import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../requests/users/types";
import { Avatar } from "../../../requests/avatars/types";
import useAvatars from "../../../requests/avatars";
import useUsers from "../../../requests/users";
import { functions } from "../../../styles";
import { ProfileSetupContext } from "..";
import Styles from "./styles"



type ColoredAvatar = Avatar & {
  color?: string;
}
function AvatarSelect() {
  const navigate = useNavigate();
  const { listAvatars } = useAvatars();
  const { updateProfile } = useUsers();
  const { user } = useOutletContext<ProfileSetupContext>();
  const [avatars, setAvatars] = useState<ColoredAvatar[]>([]);
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar>();


  /****************************************/
  /** Update Profile with Selected Avatar */
  const saveAvatar = (e: MouseEvent) => {
    if (!selectedAvatar) return;
    e.preventDefault();
    const profileData: UpdateProfileArgs = {
      avatarId: selectedAvatar.id
    }
    updateProfile(profileData)
      .then(() => {
        navigate(profileSetupPaths.HERITAGES);
      }).catch(() => null);
  }

  /*********************/
  /** List All Avatars */
  const getAvatars = useCallback(() => {
    listAvatars()
      .then((resData) => {
        const avatars: ColoredAvatar[] = resData.map((avatar) => ({
          ...avatar,
          color: functions.rgba(functions.randomColor(), 0.2)
        }));
        setAvatars(avatars);
      }).catch(() => null);
  }, [])


  useEffect(() => {
    getAvatars();
  }, [getAvatars])

  useEffect(() => {
    if (!user) return;
    if (!avatars.length) return;
    const { avatarId } = user;
    if (avatarId) {
      const initialAvatar = avatars.find(a =>
        a.id === avatarId
      );
      if (initialAvatar)
        setSelectedAvatar(initialAvatar);
    }
  }, [user, avatars])



  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Choose Avatar
        </h3>
      </header>

      {/* Avatar Selection */}
      <section className="avatar-select">
        <ul className="avatar-list">
          {avatars.map((avatar) =>
            <li
              key={avatar.id}
              className={`avatar-wrapper ${(
                selectedAvatar?.id === avatar.id
              ) ? "active" : ""}`}
            >
              <button
                className="avatar"
                style={{ backgroundColor: `${avatar.color}` }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedAvatar(avatar);
                }}
              >
                <img src={avatar.url} alt="" />
              </button>
            </li>
          )}
        </ul>
        {selectedAvatar ? (
          <button
            className="button submit save-avatar"
            disabled={!selectedAvatar}
            onClick={saveAvatar}
          >
            Use this Avatar
          </button>
        ) : null}
      </section>
    </Styles>
  );
}

export default AvatarSelect