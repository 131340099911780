import SubscriptionList from "../../../Subscriptions/List";
import Styles from "./styles";



function UpgradeOptions() {
  return (
    <Styles className="upgrade-options">
      <SubscriptionList />
    </Styles>
  );
}

export default UpgradeOptions;