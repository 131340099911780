import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  .values-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    background-color: ${colors.grey15};
    border-radius: 7px;
    border: 1px solid ${colors.grey1};
    padding: 16px 20px;
    .text-field {
      background-color: unset;
      border: none;
      &:not(:last-child) {
        &::after {
          content: "/";
          color: ${colors.grey2};
          padding: 0 5px;
        }
      }
      input {
        padding: 0;
        text-align: center;
      }
    }
  }
  .selected-dob {
    text-align: center;
    color: ${colors.grey6};
    margin-top: 1rem;
    font-size: 0.8rem;
  }
`;

export default Styles;