import { getName } from "country-list";
import { ReactNode } from "react";
import moment from "moment";

import { Address } from "../../../requests/addresses/types";
import { User } from "../../../requests/users/types";



type UserData = Partial<{
  [k in keyof User]: {
    description: string;
    content?: (user: User) => ReactNode;
  }
}>

type AddressData = Partial<{
  [k in keyof Address]: {
    description: string;
    content?: (address: Address) => ReactNode;
  }
}>

const useAccountUtils = () => {
  const userData: UserData = {
    firstName: {
      description: "First Name"
    },
    middleName: {
      description: "Middle Name"
    },
    lastName: {
      description: "Last Name"
    },
    nickName: {
      description: "Display Name"
    },
    dob: {
      description: "Date of Birth",
      content: (user) => (
        user.dob ? (
          moment(user.dob).format("Do MMMM, YYYY")
        ) : ""
      )
    }
  }

  const addressData: AddressData = {
    addressLine1: {
      description: "Address Line 1"
    },
    city: {
      description: "City"
    },
    postCode: {
      description: "Post Code"
    },
    country: {
      description: "Country",
      content: (address) => (
        address.country ? (
          `${getName(address.country)} (${address.country})`
        ) : ""
      )
    },
    countryCode: {
      description: "Phone Number",
      content: (address) => (
        (address.countryCode && address.phoneNumber) ? (
          `+${address.countryCode} ${parseInt(`${address.phoneNumber}`)}`
        ) : ""
      )
    }
  }



  return {
    userData,
    addressData
  }
}

export default useAccountUtils;