import { Link } from "react-router-dom";

import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import error from "../../../assets/vector-images/panel/error.svg";
import { InfoPanel } from "../../../components/layout";



function SubscriptionFailed() {
  return (
    <div className="page-wrapper flex column center">
      <InfoPanel
        icon={error}
        title="Error with Subscription"
        description={(
          `We encountered an error billing your${" "}
            card for this subscription. Please try again.`
        )}
      >
        <Link
          to={subscriptionPaths.LIST}
          className="button error"
        >
          Try Again
        </Link>
      </InfoPanel>
    </div>
  );
}

export default SubscriptionFailed;
