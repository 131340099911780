import ReactPlayer from "react-player";

import Styles from "./styles";
import { BaseReactPlayerProps } from "react-player/base";



interface Props extends BaseReactPlayerProps {
  url: string;
}

function VideoPlayer(props: Props) {


  return (
    <Styles className="video-player flex column">
      <ReactPlayer
        {...props}
        width="100%"
        height="100%"
        controls={true}
      />
    </Styles>
  );
}

export default VideoPlayer;