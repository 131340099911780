import { Link } from "react-router-dom";

import { consolePaths } from "../../../../routes/_console/paths";
import success from
  "../../../../assets/vector-images/panel/success-squigly.svg";
import { InfoPanel } from "../../../../components/layout";



function KycVerificationSuccess() {
  return (
    <div className="page-wrapper">
      <InfoPanel
        icon={success}
        title="Identity Validation Successful"
        description={(
          <div>
            We've successfully validated your identity{" "}
            using the details submitted.
            <br />
            Glad to have you join SONA!
          </div>
        )}
      >
        <Link
          to={consolePaths.DASHBOARD}
          className="button white-primary"
        >
          CONTINUE
        </Link>
      </InfoPanel>
    </div>
  );
}

export default KycVerificationSuccess;