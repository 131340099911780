import { Link } from "react-router-dom";

import { profileSetupPaths } from "../../../../routes/profileSetup/paths";
import {
  ReactComponent as AddressIcon
} from "../../../../assets/vector-images/kyc-manual/residence.svg";
import {
  ReactComponent as TaskIcon
} from "../../../../assets/vector-images/kyc-manual/task-list.svg";
import {
  ReactComponent as IdIcon
} from "../../../../assets/vector-images/kyc-manual/id-card.svg";
import Styles from "./styles";



function UploadInstructions() {
  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Document Upload Instructions
        </h3>
      </header>
      {/* Proof of Identification */}
      <section className="proof-of-id">
        <div className="head flex align-center">
          <div className="icon">
            <IdIcon />
          </div>
          <h3 className="title">
            Proof of Identification
          </h3>
        </div>
        <div className="content">
          <div className="list-title">
            SONA uses the following documents as proof of identification{" "}
            for user verification:
          </div>
          <ul className="list">
            <li>Driving license</li>
            <li>Passport</li>
            <li>National ID Card</li>
            <li>Residence permit (containing date of birth)</li>
          </ul>
        </div>
      </section>

      {/* Proof of Address */}
      <section className="proof-of-address">
        <div className="head flex align-center">
          <div className="icon">
            <AddressIcon />
          </div>
          <h3 className="title">
            Proof of Address
          </h3>
        </div>
        <div className="list-title">
          SONA requires the following documents as proof of address{" "}
          for user verification:
        </div>
        <ul className="list">
          <li>
            Bank Statement (within the last 3 months)
          </li>
          <li>
            Credit Card Statement (within the last 3 months)
          </li>
          <li>
            Utility Bill (such as water, electricity, gas,{" "}
            landline, home internet, and issued within the last 3 months)
          </li>
          <li>
            Residence permit (with address information)
          </li>
          <li>
            Government-Issued Documentation  (including documents from{" "}
            local authorities for address registration, tax or social{" "}
            benefits, and issued within the last 3 months)
          </li>
        </ul>
      </section>

      {/* Accepted Document Formats */}
      <section className="accepted-formats">
        <div className="head flex align-center">
          <div className="icon">
            <TaskIcon />
          </div>
          <h3 className="title">
            Acceptable Document Formats
          </h3>
        </div>
        <div className="paragraphs">
          <p>
            Your identification documents must not be a picture within {" "}
            another document (such as a passport pasted into a Word{" "}
            document), copied, altered in any way, or taken as a{" "}
            screenshot. They also cannot be expired, invalid, or of{" "}
            an unacceptable type.
          </p>
          <p>
            For proof of address documents, the original format must be{" "}
            uploaded if they are in electronic form (such as a PDF for{" "}
            bank statements), and screenshots are not acceptable.{" "}
            Additionally, the documents cannot be expired, invalid, or{" "}
            of an unacceptable type.
          </p>
          <p>
            To mininmize the likelihood of your documents being rejected{" "}
            for not meeting our requirements, please upload{" "}
            high-resolution photos or original digital copies of the{" "}
            full documents for proof of address.
          </p>
        </div>
      </section>

      <Link
        to={profileSetupPaths.KYC_UPLOAD_DOCUMENT}
        className="button"
      >
        Understood
      </Link>
    </Styles>
  );
}

export default UploadInstructions