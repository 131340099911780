import styled from "styled-components";

import {
  colors, properties, resolutions, snippets
} from "../../styles";



const Styles = styled.div`
  position: relative;  
  background-color: unset !important;
  flex-direction: row !important;
  width: 100%;
  margin: 0 auto;
  max-width: ${properties.maxPageWidth};
  max-height: calc(100vh - ${properties.navHeight});
  overflow: hidden;
  ${snippets.scrollBar}

  #Console {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 2rem;
    padding-right: 2rem;
    @media(max-width: ${resolutions.tabletLarge}) {
      margin: 2rem 1rem;
      padding-right: 1rem;
    }
    .onboarding-prompt {
      padding: 0.5rem 1rem;
      background-color: ${colors.accent2};
      border-radius: 10px;
      margin-bottom: 1rem;
      gap: 1rem;
    }
    ${'' /** Page Title */}
    .page-title {
      font-weight: 700;
      font-size: 17px;
      margin-bottom: 1rem;
      text-transform: uppercase;
      padding: 1rem 0;
      display: none;
    }

    ${'' /* Page Content */}
    .page-wrapper {
      display: flex;
      flex-direction: column;
      background-color: ${colors.white};
      border-radius: 30px;
      @media(max-width: ${resolutions.tabletLarge}) {
        padding: 2rem 1rem;
      }
      @media(max-width: ${resolutions.tablet}) {
        padding: 0;
        background-color: transparent;
        border-radius: unset;
      }
      header {
        figure.flex {
          gap: 10px;
          img {
            width: 27px;
          }
          .title {
            font-size: 1rem;
            line-height: 25px;
          }
        }
      }
    }

    ${'' /* Console Footer */}
    .console-footer {
      margin-top: 2rem;
      flex-wrap: wrap;
      row-gap: 1rem;
    }
  }
`;

export default Styles;