import { getVerboseFamilyRole } from "../../../constants/auth";
import { User } from "../../../requests/users/types";
import Styles from "./styles";



export interface UserAvatarProps extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>, HTMLDivElement
> {
  user: User;
  description?: string;
}

function UserAvatar(props: UserAvatarProps) {
  const { user, description, ...otherProps } = props;



  return (
    <Styles
      className="user-avatar flex"
      {...otherProps}
    >
      {user.avatar ? (
        <img
          className="image"
          src={user.avatar.url}
          alt=""
        />
      ) : (
        <div className="avatar-placeholder">
          {user.firstName?.charAt(0)}
          {user.lastName?.charAt(0)}
        </div>
      )}
      <figcaption className="details flex column justify-center">
        <div className="name flex">
          <div>
            {`${user.firstName}${" "}
            ${user.lastName}`}
          </div>
          <div className={`role ${user.familyRole}`}>
            {getVerboseFamilyRole(user.familyRole)}
          </div>
        </div>
        <div className="description">
          {description
            ? description
            : user.username
          }
        </div>
      </figcaption>
    </Styles>
  );
}

export default UserAvatar;