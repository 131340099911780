import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../../styles";
import lock_icon from "../../../assets/vector-images/generic/lock.svg";



const cardWidth = "300px";
const cardHeight = "400px";
const treeStateHeight = "300px";
const pagePadding = "2rem";

const Styles = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;

  ${''/* Mission Progress Bar */}
  .mission-progress-bar {
    z-index: 1;
    position: absolute;
    top: calc(${treeStateHeight});
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - (${pagePadding}));
    width: calc(100% - (${pagePadding} * 2));
    .tree {
      width: 5px;
      height: inherit;
      margin: 0 auto;
      position: relative;
      background-color: ${colors.grey1};
      .fill {
        position: absolute;
        top: 0;
        background-color: ${colors.primary};
        width: 100%;
        height: 100%;
        animation-delay: 1s;
        animation: fillTree 1s linear;
        animation-fill-mode: forwards;
        transform-origin: top;
      }
      @keyframes fillTree {
        from {
          transform: scaleY(0);
        }
        to {
          transform: scaleY(1);
        }
      }
    }
    .branches {
      .branch {
        position: absolute;
        background-color: ${colors.grey1};
        height: 5px;
        width: 50%;
        &:nth-child(odd) {
          align-self: flex-start;
          .fill {
            transform-origin: right;
          }
        }
        &:nth-child(even) {
          align-self: flex-end;
          .fill {
            transform-origin: left;
          }
        }
        .fill {
          height: 100%;
          width: 100%;
          animation: fillBranch 1s linear;
          animation-fill-mode: forwards;
          background-color: ${colors.primary};
        }
      }
      @keyframes fillBranch {
        from {
          transform: scaleX(0);
        }
        to {
          transform: scaleX(1);
        }
      }
    }
  }

  ${''/* Tree Environment State */}
  .tree-state {
    height: ${treeStateHeight};
    box-shadow: ${properties.boxShadow};
    border-radius: 30px;
    position: relative;
    margin-bottom: 5rem;
    z-index: 2;
  }

  ${''/* User Missions */}
  .user-missions {
    position: relative;
    z-index: 2;
    .user-mission {
      gap: 0;
      border-radius: 10px;
      background-color: ${colors.white};
      width: ${cardWidth};
      height: ${cardHeight};
      border: 1px solid ${colors.grey1};
      overflow: hidden;
      box-shadow: ${properties.boxShadow};
      margin-top: calc((${cardHeight} / 3) * -1);
      &:first-child {
        margin-top: 0
      }
      &:nth-child(odd) {
        margin-right: auto;
      }
      &:nth-child(even) {
        margin-left: auto;
      }
      .cover-image {
        position: relative;
        height: 250px;
        &::after {
          content: "";
          ${snippets.absoluteCenter};
          display: none;
          width: 100%;
          height: 100%;
          background-color: ${functions.rgba(colors.black, 0.7)};
          background-image: url(${lock_icon});
          background-position: center;
          background-size: 40px;

        }
        &.locked {
          &::after {
            display: block;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .details {
        padding: 1.5rem 1rem;
        flex: 1;
        .title {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        .tokens {
          margin-top:1rem;
          .role {
            font-size: 0.8rem !important;
            height: unset !important;
          }
        }
      }
    }
  }
`;

export default Styles;