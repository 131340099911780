import styled from "styled-components";

import { colors, properties, snippets } from "../../styles";



const Styles = styled.div`
  .profile-setup-wrapper {
    flex: 1;
    width: 100%;
    max-width: ${properties.maxFormWidth};
    ${snippets.flexCenter};
    margin: 0 auto;
    ${'' /* Pogress Bar */}
    .progress-bar {
      margin-bottom: 4rem;
      .title {
        font-weight: 700;
        font-size: 14px;
      }
      .value {
        display: none;
      }
    }
    ${'' /* Outlet */}
    .page-wrapper {
      margin: 0;
      justify-content: flex-start;
      align-items: center;
      max-width: ${properties.maxFieldWidth};
      header {
        text-align: center;
        margin-bottom: 2rem;
        .subtitle {
          color: ${colors.grey6};
          margin-top: 1rem;
          line-height: 23px;
        }
      }
    }
  }
`;

export default Styles;