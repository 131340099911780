import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  max-width: ${properties.maxFieldWidth} !important;
  .checklist-items {
    gap: 1rem;
    .checklist-item {
      gap: 1.5rem;
      border: 1px solid ${colors.grey1};
      border-radius: 10px;
      background-color: ${colors.white};
      padding: 1.25rem 1rem;
      .image {
        height: 20px;
        width: 20px;
        object-fit: cover;
        border-radius: 50%;
        &.placeholder {
          border: 1px solid ${colors.grey1};
        }
      }
      .title {
        font-weight: 600;
      }
    }
  }

  .go-home {
    display: block;
    margin: 0 auto;
    margin-top: 2rem;
  }
`;

export default Styles;