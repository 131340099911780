import { PlanPriceInterval } from "../requests/plans/types";



export const getPlanInterval = (interval: PlanPriceInterval) => {
  switch (interval) {
    case "day":
      return "Daily";
    case "week":
      return "Weekly";
    case "month":
      return "Monthly";
    case "year":
      return "Yearly";
    default:
      return "Monthly"
  }
}