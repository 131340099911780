/*****************************************/
/** Get Root Path from a pathname string */
export const getRootPath = (pathname: string, originRoot: string) => {
  const segments = pathname.split("/")
    .filter(segment => segment !== "");
  const originSegments = originRoot.split("/")
    .filter(segment => segment !== "");
  if ((segments.length > 0) && originSegments.length > 0) {
    const rootSegment = segments[0];
    const originSegment = originSegments[0]
    if (rootSegment !== originSegment)
      return `/${rootSegment}`;
  }

  return "";
}