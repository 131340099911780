const loadMessages = [
  "Ananse is weaving your Missions",
  "Just a sec... Good things come to those who wait!",
  "Ananse is crafting your next adventure",
  "Hold tight, your Missions are on their way!",
  "Ananse is spinning the web of your quests",
  "Just a moment, preparing your exciting journey!",
  "Ananse is setting up your epic challenges",
  "Ananse is organizing your heroic tasks.",
  "Hang in there, great adventures are loading!",
  "Ananse is fine-tuning your Missions",
  "Fetching your next big adventure...",
  "Ananse is working his magic on your tasks",
  "Your Missions are almost ready, stay tuned!",
  "Ananse is cooking up some epic quests for you",
  "Just a bit longer, your journey is loading...",
  "Ananse is piecing together your adventures",
  "Hang tight, excitement is on its way!",
  "Ananse is arranging your quests, almost there!",
  "Your epic journey is being prepared, please wait...",
  "Ananse is making sure everything is perfect for your Missions"
]


const useMissionListUtils = () => {
  function getLoadMessage() {
    return loadMessages[Math.floor(Math.random() * loadMessages.length)];
  }

  return {
    getLoadMessage
  }
}

export default useMissionListUtils;