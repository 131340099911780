import { useAppModal } from "../../components/layout/AppModal/utils";

import { ActiveSubscription, DowngradeCheckArgs, SubscribeToPlanArgs, Subscription } from "./types";
import useSubscriptions from ".";



const useSubscriptionUtils = () => {
  const { setAppModal, closeModal } = useAppModal();
  const { startSubscription } = useSubscriptions();


  /***************************/
  /** Downgrade confirmation */
  const confirmDowngrade = (
    planName: string
  ) => {
    return new Promise<boolean>((resolve, reject) => {
      setAppModal({
        title: "Downgrade Plan?",
        children: (
          `Are you sure you want to downgrade to the ${planName} Plan?`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-primary"
              onClick={() => {
                closeModal();
                resolve(true);
              }}
            >
              Yes, I'll downgrade
            </button>
            <button
              className="button"
              onClick={() => {
                closeModal();
                reject(false);
              }}
            >
              No, keep my current plan
            </button>
          </div>
        )
      });
    });
  }

  /*****************************/
  /** Inform user of downgrade */
  const downgradeCheck = ({
    plan,
    activeSubscription,
  }: DowngradeCheckArgs) => {
    if (
      plan.id < activeSubscription.planPrice.planId &&
      plan.name !== activeSubscription.planPrice.plan.name
    ) {
      return confirmDowngrade(plan.name);
    }
    return Promise.resolve();
  }

  /*************************************/
  /** Get payment URL and pay for Plan */
  const subscribeToPlan = ({
    plan,
    planPrice,
    activeSubscription,
  }: SubscribeToPlanArgs) => {
    const proceedWithSubscription = () => {
      const data = {
        planPriceId: planPrice.id,
      };
      startSubscription(data)
        .then((resData) => {
          window.location.href = resData.paymentUrl;
        }).catch(() => null);
    }
    if (activeSubscription) {
      downgradeCheck({
        plan,
        planPrice,
        activeSubscription,
      }).then(proceedWithSubscription)
        .catch(() => null);
    } else {
      proceedWithSubscription(); // No current subscription, purchase
    }
  }


  return {
    subscribeToPlan
  }
}

export default useSubscriptionUtils;


/*********************************************/
/** Check if user has an active subscription */
export const hasActiveSubscription = (
  subscription: Subscription | null
): ActiveSubscription | null => {
  if (
    subscription &&
    subscription.planPrice &&
    !subscription.expired
  ) {
    return subscription as ActiveSubscription;
  } else {
    return null;
  }
}