import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import Styles from "./styles";



export type MissionsContext = AuthRouteContext;
function Missions() {
  const missionsContext = useOutletContext<MissionsContext>();

  return (
    <Styles className="root-content">
      <Outlet context={missionsContext} />
    </Styles>
  );
}

export default Missions;