import { Link } from "react-router-dom";

import play_store from "../../../assets/vector-images/brands/play-store.svg";
import app_store from "../../../assets/vector-images/brands/app-store.svg";
import { consolePaths } from "../../../routes/_console/paths";
import Styles from "./styles";



function DownloadApp() {
  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Download the SONA App
        </h3>
        <div className="description">
          Continue on the app by downloading from the{" "}
          App Store or Google Play Store.
          <br /><br />
          Sign in using same details you used when creating{" "}
          your account.
        </div>
      </header>

      {/* App external links */}
      <section className="flex align-center app-links">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.apple.com/app/sona-school-of-new-africa/id6465394291"
          className="store-link"
        >
          <img src={app_store} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.schoolofnewafrica.app"
          className="store-link"
        >
          <img src={play_store} />
        </a>
      </section>

      {/* Alt app links  */}
      <section className="alt-app-links">
        <div>
          Buttons above not working?
          Use these links to download:
        </div>
        <br />
        <div className="flex column app-list">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/app/sona-school-of-new-africa/id6465394291"
            className="link black"
          >
            📱 Download on <span>iPhone</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.schoolofnewafrica.app"
            className="link black"
          >
            📱 Download on <span>Android</span>
          </a>
        </div>
      </section>

      {/* Go To Account */}
      <section className="go-account">
        <Link
          to={consolePaths.DASHBOARD}
          className="button white-black"
        >
          GO TO MY ACCOUNT
        </Link>
      </section>
    </Styles>
  );
}

export default DownloadApp;