import { getName } from "country-list";

import { SelectFieldOption } from "../components/forms/SelectField";
import { getFlagEmoji } from "../utils/images";



export type URLParams =
  | "userId"
  | "familyId"
  | "clanId"
  | "familyInviteId"
  | "clanInviteId"
  | "desertId"
  | "missionId"
  | "userMissionId"


const countryCodes: SelectFieldOption[] = [
  {
    value: "1",
    label: "US"
  },
  {
    value: "44",
    label: "GB"
  },
  {
    value: "55",
    label: "BR"
  },
  {
    value: "233",
    label: "GH"
  },
  {
    value: "234",
    label: "NG"
  }
]

function twoWords(str: string) {
  const words = str.split(' ');
  return words.slice(0, 2).join(' ');
}

export const getCountryCodes = (): SelectFieldOption[] => {
  return countryCodes.map(country => ({
    value: country.value,
    label: (`${getFlagEmoji(country.label)
      } ${twoWords(getName(country.label) ?? "")
      } (+${country.value})`)
  }));
}