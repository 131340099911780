import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { Heritage } from "./types";



/*  ########################## */
/** ## HERITAGE API REQUESTS # */
/*  ########################## */
const useHeritages = () => {
  /**************************/
  /** List User's Heritages */
  const listUserHeritages = () => {
    return new Promise<PaginatedResponse<Heritage>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/heritages/`
      }).then((res) => {
        resolve(res.data as PaginatedResponse<Heritage>);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listUserHeritages
  }
}

export default useHeritages;