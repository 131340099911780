import { useEffect, useRef } from "react";

import { FormField } from "../../../components/forms/Form/types";
import { Form } from "../../../components/forms";
import { FormPageProps } from "./types";



function Name({
  FormNavigation,
  values: formValues,
  setValues: setFormValues,
  nextStep,
  user,
  emailUsername
}: FormPageProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const usersPronoun = emailUsername ? "parent's" : "learner's";


  /****************/
  /** Form Fields */
  const fields: FormField[] = [
    {
      type: "react-node",
      name: "",
      label: "",
      groupClass: "field-group mb-1",
      reactNode: (
        <div className="field-info bold">
          Kindly enter the {usersPronoun} name as it appears on their government-issued ID.
          <br />
          This is required for the SONA card program.
        </div>
      )
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      maxLength: 30,
      placeholder: "First Name",
      groupClass: "field-group"
    },
    {
      type: "text",
      name: "middleName",
      label: "Middle Name",
      required: false,
      maxLength: 15,
      placeholder: "Middle Name",
      groupClass: "field-group"
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      maxLength: 30,
      placeholder: "Last Name",
      groupClass: "field-group"
    }
  ]


  useEffect(() => {
    if (!user) return;
    const { firstName, middleName, lastName } = user;
    setFormValues((prevState) => ({
      ...prevState,
      firstName,
      middleName,
      lastName
    }));
  }, [user])



  return (
    <Form
      values={formValues}
      setValues={setFormValues}
      onSubmit={nextStep}
      ref={formRef}
      fields={fields}
    >
      <FormNavigation />
    </Form>
  );
}

export default Name;