
import { RouteType } from "../types";
import { downloadPaths } from "./paths";
import {
  DownloadApp
} from "../../pages/Download/pages";



/********************/
/** Download Routes */
export const downloadRoutes: RouteType[] = [
  {
    path: downloadPaths.DOWNLOAD_APP,
    element: <DownloadApp />
  }
]