import styled from "styled-components";
import { resolutions } from "../../../styles";



const Styles = styled.div`
  .family-crest {
    background: unset;
    padding: 0;
    .image {
      height: 50px;
      width: 50px;
    }
  }
  .add-members {
    @media(max-width: ${resolutions.phone}) {
      margin-top: 1rem;
      flex-direction: row;
    }
  }
  .user-list {
    margin-top: 2rem;
  }
`;

export default Styles;