import seed from "../../../assets/raster-images/mission/seed.png";
import cup from "../../../assets/raster-images/mission/cup.png";
import map from "../../../assets/raster-images/mission/map.png";



export type MissionItem = {
  name: string;
  icon: string;
  description: string;
}

export const missionItems: MissionItem[] = [
  {
    name: "Seed of Love",
    icon: seed,
    description: ""
  },
  {
    name: "Cup of Knowledge",
    icon: cup,
    description: ""
  },
  {
    name: "Map of Space & Time",
    icon: map,
    description: ""
  }
]