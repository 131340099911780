import styled from "styled-components";

import SignUpStyles from "../SignUp/styles";



const Styles = styled(SignUpStyles)`

`;

export default Styles;