import { missionPaths } from "./paths";
import { RouteType } from "../types";
import {
  MissionLesson,
  MissionQuiz,
  MissionResult,
  MapOfSpaceAndTime,
  DesertMissions,
  OnboardingLesson,
  OnboardingResult,
  OnboardingQuiz,
} from "../../pages/Missions/pages";



/*******************/
/** Mission Routes */
export const missionRoutes: RouteType[] = [
  {
    path: missionPaths.map(),
    element: <MapOfSpaceAndTime />
  },
  {
    path: missionPaths.DESERT_MISSIONS,
    element: <DesertMissions />
  },
  {
    path: missionPaths.MISSION_LESSON,
    element: <MissionLesson />
  },
  {
    path: missionPaths.MISSION_QUIZ,
    element: <MissionQuiz />
  },
  {
    path: missionPaths.MISSION_RESULT,
    element: <MissionResult />
  }
]

export const onboardingMissionRoutes: RouteType[] = [
  {
    path: missionPaths.ONBOARDING_LESSON,
    element: <OnboardingLesson />
  },
  {
    path: missionPaths.ONBOARDING_QUIZ,
    element: <OnboardingQuiz />
  },
  {
    path: missionPaths.ONBOARDING_RESULT,
    element: <OnboardingResult />
  }
]