import styled from "styled-components";

import { properties } from "../../../styles";



const Styles = styled.div`
  ${''/* All Sections */}
  &>* {
    max-width: ${properties.maxFieldWidth};
  }
  section {
    margin: 3rem 0;
  }
  .alt-app-links {
    .app-list {
      gap: 20px;
      .link {
        span {
          text-decoration: underline
        }
      }
    }
  }
`;

export default Styles;