import axios from "axios";

import { ErrorResponse } from "../../types";
import { Onboarding } from "./types";



/*  ############################ */
/** ## ONBOARDING API REQUESTS # */
/*  ############################ */
const useOnboarding = () => {
  /*********************************************/
  /** Get the onboarding of the logged-in user */
  const getMyOnboarding = () => {
    return new Promise<Onboarding>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/onboarding/my-onboarding/`
      }).then((res: any) => {
        const resData = res.data as Onboarding;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  return {
    getMyOnboarding
  }
}

export default useOnboarding;