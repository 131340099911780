import { useEffect, useState } from "react";

import { MissionQuizzes } from "../../../components/missions";
import { Quiz } from "../../../requests/missions/types";
import useMissions from "../../../requests/missions";
import { Loader } from "../../../components/layout";
import { missionPaths } from "../../../routes/missions/paths";



function OnboardingQuizzes() {
  const { getOnboardingMission } = useMissions();
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);


  /***************************/
  /** Get Onboarding Mission */
  const retrieveOnboardingMission = () => {
    getOnboardingMission()
      .then((mission) => {
        setQuizzes(mission.quizzes);
      }).catch(() => null);
  }


  useEffect(() => {
    retrieveOnboardingMission();
  }, [])



  return (
    <div className="root-content">
      <div className="page-wrapper">
        {quizzes.length ? (
          <MissionQuizzes
            quizzes={quizzes}
            lessonLink={missionPaths.ONBOARDING_LESSON}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default OnboardingQuizzes;

