import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import delete_icon from "../../../assets/vector-images/generic/delete.svg";
import { ClipboardCopy, InfoPanel } from "../../../components/layout";
import { FamilyInvite } from "../../../requests/familyInvites/types";
import useFamilyInvites from "../../../requests/familyInvites";
import { getVerboseFamilyRole } from "../../../constants/auth";
import { familyPaths } from "../../../routes/family/paths";
import useViewInviteUtils from "./utils";
import Styles from "./styles";



function ViewInvites() {
  const { pathname } = useLocation();
  const { listSentFamilyInvites } = useFamilyInvites();
  const { deleteInviteConfirmation } = useViewInviteUtils();
  const [familyInvites, setFamilyInvites] = useState<FamilyInvite[]>([]);


  useEffect(() => {
    listSentFamilyInvites()
      .then((response) => {
        const { results } = response;
        setFamilyInvites(results);
      }).catch(() => null)
  }, [])



  return (
    <Styles className="page-wrapper">
      {(familyInvites.length > 0) ? (
        <>
          {/* Heading */}
          <header className="flex justify-between align-start">
            <div className="first-half">
              <div className="title">
                Invite Links
              </div>
              <div className="description">
                Share invite links with your loved ones{" "}
                so they can join your family account.
              </div>
            </div>
            <Link
              to={familyPaths.createInvite(pathname)}
              className="button sm"
            >
              Invite Someone
            </Link>
          </header>

          {/* Family Invite links list */}
          <section>
            <ul className="family-invite-links flex column">
              {familyInvites.map((invite) =>
                <li className="invite-link-card" key={invite.id}>
                  <div className="flex column">
                    <div className="flex justify-between">
                      {/* Invited User */}
                      <div className="invitee">
                        <div className="target flex align-center">
                          <div className="name">
                            {invite.inviteeUsername
                              ? invite.inviteeUsername
                              : "Global Link"
                            }
                          </div>
                          <div className={`role ${invite.role}`}>
                            {getVerboseFamilyRole(invite.role)}
                          </div>
                        </div>
                        <div className="detail">
                          {invite.inviteeUsername
                            ? "Only this person can use this link"
                            : "Anyone with this link can join your Family"
                          }
                        </div>
                      </div>
                      <div className="flex">
                        {/* Copy link to Clipboard */}
                        <ClipboardCopy
                          text={invite.inviteLink}
                        />
                        {/* Delete Invite */}
                        <button
                          className="icon-button"
                          onClick={(e) => deleteInviteConfirmation(e, invite)}
                        >
                          <img src={delete_icon} alt="" />
                        </button>
                      </div>
                    </div>
                    {/* Invite link that opens a new tab */}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={invite.inviteLink}
                      className="invite-link"
                    >
                      {invite.inviteLink}
                    </a>
                  </div>
                </li>
              )}
            </ul>
            <div className="foot-section mlr-auto flex column center">
              <div className="grey-text mt-2">
                You can invite others by sharing these{" "}
                links with them to sign up on SONA
              </div>
              <Link
                to={familyPaths.detail(pathname)}
                className="button sm white-primary mlr-auto auto-width mt-1"
              >
                Go to My Family
              </Link>
            </div>
          </section>
        </>
      ) : (
        // No Invite Links
        <InfoPanel
          title="No Invite Links"
          description={(
            <div>
              It seems you currently don't have any invite links.
              <br />
              However, you can create one by clicking the button below.
            </div>
          )}
        >
          <Link
            to={familyPaths.createInvite(pathname)}
            className="button submit"
          >
            Create Invite Link
          </Link>
        </InfoPanel>
      )}
    </Styles>
  );
}

export default ViewInvites;