import Styles from "./styles";



function MissionDetail() {
  return (
    <Styles className="page-wrapper">

    </Styles>
  );
}

export default MissionDetail;