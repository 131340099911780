import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";

import { profileSetupPaths } from "../../../../routes/profileSetup/paths";
import { UpdateProfileArgs } from "../../../../requests/users/types";
import { consolePaths } from "../../../../routes/_console/paths";
import { Spinner } from "../../../../components/layout";
import useFamilies from "../../../../requests/families";
import { Kyc } from "../../../../requests/kyc/types";
import useUsers from "../../../../requests/users";
import useKyc from "../../../../requests/kyc";
import { ProfileSetupContext } from "../..";



function KycCheck() {
  const navigate = useNavigate();
  const { autoKyc } = useKyc();
  const { getPrimaryAdmin } = useFamilies();
  const { updateProfile } = useUsers();
  const { user } = useOutletContext<ProfileSetupContext>();
  const [firstRender, setFirstRender] = useState<boolean>(false);


  /******************************************/
  /** Change User's Onboaring state via API */
  const finalizeOnboarding = async () => {
    const profileData: UpdateProfileArgs = {
      completedOnboarding: true
    }

    return updateProfile(profileData);
  }


  /**********************/
  /** Handle KYC States */
  const handleKycStatus = async (kyc: Kyc) => {
    switch (kyc.status) {
      case "unstarted":
        navigate(profileSetupPaths.NAME_ENTRY);
        break;
      case "processing":
        navigate(profileSetupPaths.KYC_VERIFICATION_PENDING);
        break;
      case "pass":
        navigate(profileSetupPaths.KYC_VERIFICATION_SUCCESS);
        break;
      case "fail":
      case "autoFail":
        navigate(profileSetupPaths.KYC_VERIFICATION_FAILED);
        break;
      case "docFail":
        if (kyc.docUploadCount <= 3) {
          navigate(profileSetupPaths.KYC_UPLOAD_DOCUMENT);
        } else {
          navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
        }
        break;
      case "completeFail":
        navigate(profileSetupPaths.KYC_COMPLETE_FAIL);
        break;
      default:
        navigate(profileSetupPaths.KYC_UPLOAD_DOCUMENT);
        break;
    }
  }

  /***************************/
  /** Perform Auto KYC Check */
  const performKycCheck = async () => {
    try {
      const kyc = await autoKyc();
      if (kyc) {
        handleKycStatus(kyc);
      } else {
        // User is not on Card Program. Go to dashboard
        navigate(consolePaths.DASHBOARD);
      }
    } catch (error: any) {
      const { status } = error;
      if (status === 403) // exhausted autokyc tries
        navigate(profileSetupPaths.KYC_UPLOAD_DOCUMENT);
      else
        navigate(profileSetupPaths.NAME_ENTRY);
    } finally {
      await finalizeOnboarding();
    }
  }

  /*******************************************/
  /** Determine if this user can perform KYC */
  const attemptKycCheck = async () => {
    let shouldPerformKyc = true;
    const familyAdmin = await getPrimaryAdmin();
    if (familyAdmin) {
      shouldPerformKyc = (
        (familyAdmin.id === user.id) &&
        user.onCardProgram
      );
    } else {
      // Fallback if the primary admin is not set
      shouldPerformKyc = (
        (user.familyRole === "admin") &&
        user.onCardProgram
      );
    }
    if (shouldPerformKyc) {
      performKycCheck();
    } else {
      finalizeOnboarding().then(() => {
        navigate(consolePaths.DASHBOARD);
      }).catch(() => null);
    }
  }


  useEffect(() => {
    if (!user) return;
    if (firstRender) return;
    setFirstRender(true);
    attemptKycCheck();
  }, [user]);



  return (
    <div className="page-wrapper">
      <div className="auto-kyc flex column center">
        <Spinner />
        <div className="message al-center">
          Please wait while we verify your information.
          This may take several minutes
        </div>
      </div>
    </div>
  )
}

export default KycCheck;