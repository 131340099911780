import styled from "styled-components";
import { colors, functions } from "../../../styles";


const Styles = styled.div`
  background-color: ${colors.black};
`;

export const OnStartModal = styled.figure`
  .img-wrapper {
    width: 90px;
    height: 90px;
    background-color: ${functions.rgba(colors.primary, 0.1)};
    border-radius: 50%;
    overflow: hidden;
    padding-top: 1rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`

export default Styles;