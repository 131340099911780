import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  align-items: center;

  ${'' /* Family Invite Link List */}
  .family-invite-links {
    gap: 2rem;
    .invite-link-card {
      padding: 1.5rem 1rem;
      border: 1px solid ${colors.grey1};
      border-radius: 10px;
      background-color: ${colors.white};
      .invitee {
        .target {
          .name {
            font-size: 1.2rem;
            font-weight: 700;
          }
        }
        .detail {
          color: ${colors.grey6};
          font-size: 0.8rem;
          margin-top: 0.25rem;
        }
      }
      .invite-link {
        margin-top: 1rem;
      }
    }
  }
`;

export default Styles;