import styled from "styled-components";

import { colors, snippets } from "../../../styles";
import { UserAvatarProps } from ".";



type StyledProps = Omit<UserAvatarProps, "user">

const Styles = styled.figcaption<StyledProps>`
  ${(props: StyledProps) => props.onClick
    ? "cursor: pointer;"
    : ""
  }
  .image {
    width: 48px;
    height: 48px;
  }
  .avatar-placeholder {
    user-select: none;
    flex-shrink: 0;
    text-transform: uppercase;
    height: 45px;
    width: 45px;
    color: #fff;
    font-size: 1rem;
    border-radius: 50%;
    background-color: ${colors.primary};
    ${snippets.flexCenter}
    font-weight: 600;
  }
  .details {
    gap: 5px;
    .name {
      .role {
        padding: 0px 5px;
        line-height: 19px;
      }
    }
    .description {
      font-size: 12px;
      color: ${colors.grey6};
    }
  }
`;

export default Styles;