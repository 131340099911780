import { useState, ChangeEvent } from "react";



type UseInputProps<T> = {
  initialValue: T;
  selectValues?: Array<string>;
  maxLength?: number;
}
export const useInput = <T>({
  initialValue,
  selectValues = [],
  maxLength
}: UseInputProps<T>) => {
  const [value, setValue] = useState<T>(initialValue);

  const stringChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let val: any = event.target.value;
    if (!maxLength) {
      setValue(val);
    } else if (maxLength && val.toString().length <= maxLength) {
      setValue(val);
    }
  };

  const numChange = (event: ChangeEvent<HTMLInputElement>) => {
    const validInt = new RegExp('^[0-9.]+$');
    if (validInt.test(event.target.value) ||
      event.target.value === ""
    ) {
      const val: any = event.target.value;
      if (!maxLength) {
        setValue(val);
      } else if (maxLength && val.toString().length <= maxLength) {
        setValue(val);
      }
    }
  };

  const selectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const val: any = event.target.value;
    if (selectValues.includes(val)) {
      setValue(val);
    } else {
      setValue(initialValue);
    }
  };

  const imageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val: any = event.target.files;
    setValue(val[0]);
  }

  return {
    value,
    setValue,

    stringChange,
    numChange,
    selectChange,
    imageChange,
  };
};