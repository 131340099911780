import styled from "styled-components";



const Styles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .go-account {
    display: none;
  }
`;

export default Styles;