import styled from "styled-components";

import { colors, properties } from "../../../styles";



export const Styles = styled.div`
  ${'' /** Style .root-header and .header-wrapper in GlobalStyles.ts */}
  .avatar-wrapper {
    position: relative;  
  }
  .dropdown-menu {
    z-index: 10;
    border-radius: 5px;
    box-shadow: ${properties.boxShadow};
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    min-width: 7rem;
    overflow: hidden;
    button {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      background-color: ${colors.white};
      &:hover {
        background-color: ${colors.accent1};
      }
    }
  }
`;

export default Styles;