import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  max-width: ${properties.maxFieldWidth};
  margin: 0 auto;
  .pre-heading {
    color: ${colors.grey2};
    margin-bottom: 1rem;
  }
  .question {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
`;

export default Styles;