import { useState, useRef, MouseEvent, FormEvent } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import useFamilies from "../../../requests/families";
import { User } from "../../../requests/users/types";
import { Form } from "../../../components/forms";



type Props = {
  user: User;
  fields: FormField[];
}

function ResetUserPassword({ user, fields }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const { resetUsersPassword } = useFamilies();
  const { setAppModal } = useAppModal();

  const resetPassword = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    resetUsersPassword(user.id, formValues.password)
      .then(() => {
        setAppModal({
          title: "Reset Password",
          children: `${user.firstName}'s password has been reset successfully!`
        });
      }).catch(() => null);
  }



  return (
    <Form
      values={formValues}
      setValues={setFormValues}
      onSubmit={resetPassword}
      ref={formRef}
      fields={fields}
    >
      <button
        className="button submit"
        type="submit"
      >
        Reset Password
      </button>
    </Form>
  );
}

export default ResetUserPassword;