import {
  useCallback, useEffect, useState, MouseEvent, FormEvent
} from "react";

import { FormNavigationProps, FormStep } from "./types";
import Styles from "./styles";



type ProgressType =
  | "title"
  | "bar"
  | "none";

type Props = {
  currentStep: number;
  steps: FormStep[];
  progressType?: ProgressType;
}

function FormSteps({
  currentStep,
  steps,
  progressType = "title"
}: Props) {
  const [formStepsRef, setFormStepsRef] = useState<HTMLDivElement>();
  const formStepsContent = useCallback((node: HTMLDivElement) => {
    if (node) setFormStepsRef(node);
  }, [])
  const [scrollRef, setScrollRef] = useState<HTMLDivElement>();
  const scrollContent = useCallback((node: HTMLDivElement) => {
    if (node) setScrollRef(node);
  }, [])


  /********************/
  /** Step navigation */
  const goToStep = (targetStep: number) => {
    if (!scrollRef) return;
    // Prevent navigating to a non-existing step;
    if (typeof steps[targetStep] === "undefined") return;
    const slideAnimation: string = `translateX(calc(${targetStep} * -100%))`;
    return new Promise<void>((resolve) => {
      if (formStepsRef) formStepsRef.scrollTop = 0;
      const animation = scrollRef.animate([{ transform: slideAnimation }], {
        duration: 150,
        fill: 'forwards',
      });
      animation.onfinish = () => {
        resolve();
      };
    });
  }


  useEffect(() => {
    goToStep(currentStep);
  }, [currentStep])



  return (
    <Styles
      className="form-steps"
      ref={formStepsContent}
    >
      {/* Form Headings */}
      {progressType === "title" ? (
        <ol className="form-headings">
          {steps.map((step, index) =>
            <li
              key={index}
              className={`form-heading ${currentStep === index ? "active" : ""}`}
            >
              <div className="number">{index + 1}</div>
              <div className="title">{step.title}</div>
            </li>
          )}
        </ol>
      ) : (progressType === "bar") ? (
        <div className="progress-bar"></div>
      ) : null}
      {/* Form Pages */}
      <div className="form-pages" ref={scrollContent}>
        {steps.map((_, index) =>
          <div className="form-page" key={index}>
            {currentStep === index ? (
              steps[index].content
            ) : null}
          </div>
        )}
      </div>
    </Styles>
  );
}

export default FormSteps;



/******************************************************/
/** Navigation Controller for the FormSteps component */
export const useFormNavigation = (initialStep: number = 0) => {
  const [currentStep, setCurrentStep] = useState<number>(initialStep);

  /************************/
  /** Go to previous step */
  const previousStep = (e?: MouseEvent | FormEvent) => {
    if (e) e.preventDefault();
    setCurrentStep((prevStep) => prevStep - 1);
  };

  /********************/
  /** Go to next step */
  const nextStep = (e?: MouseEvent | FormEvent) => {
    if (e) e.preventDefault();
    setCurrentStep((prevStep) => prevStep + 1);
  };


  /****************************************************/
  /** Recommended component for navigating Form Steps */
  function FormNavigation({
    previous,
    next
  }: FormNavigationProps) {
    return (
      <div className="form-controls">
        {(previous !== undefined) ? (
          previous
        ) : (
          <button
            className="button auto-width white-black previous"
            type="button"
            onClick={previousStep}
          >
            Previous
          </button>
        )}
        {(next !== undefined) ? (
          next
        ) : (
          <button
            className="button auto-width next"
            type="submit"
          >
            Next
          </button>
        )}
      </div>
    )
  }

  return {
    currentStep,
    setCurrentStep,
    nextStep,
    previousStep,
    FormNavigation
  }
}